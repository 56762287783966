<template>
  <li
    ref="menuItem"
    role="menuitem"
    :aria-haspopup="(items.length > 0).toString()"
    :aria-expanded="open.toString()"
    :aria-controls="`${id}__region`"
    :tabindex="menuButtonTabIndex"
    class="c-header-nav-item"
    data-cy="main-menu-child-elements"
    :class="{'u-visible': open, 'c-header-nav-item--promo': type === 'PROMOTIONS' }"
  >
    <button
      v-if="type === 'BRANDS'"
      ref="button"
      @mouseenter="hoverOn"
      @mouseleave="hoverOff"
    >
      <nuxt-link :to="localePath(url)" @click.native="scrollToTop(localePath(url))">
        {{ label }}
      </nuxt-link>
    </button>
    <button
      v-else-if="items && items.length > 0 || !url"
      ref="button"
      @mouseenter="hoverOn"
      @mouseleave="hoverOff"
    >
      {{ label }}
    </button>
    <nuxt-link
      v-else
      :to="localePath(returnUrl)"
      @click.native="reinitFilterAndRedirect"
    >
      {{ label }}
    </nuxt-link>
    <div
      v-if="items && items.length > 0"
      :id="id + '__region'"
      ref="content"
      class="c-header-nav-item__sub"
      @mouseenter="hoverOn"
      @mouseleave="hoverOff"
    >
      <MegaMenu
        :menu-id="id"
        :items="items"
        :templates="templates"
        :brands="type === 'BRANDS'"
        :url="url"
        @close="close"
      />
    </div>
  </li>
</template>
<script>
import { mapActions, mapState } from 'vuex'
// import { createTrackPromotionEvent } from '@/helpers/gtmHelper'
import { generatePathWithQueryParams } from '@/helpers/generatePathWithQueryParams'
import MegaMenu from '@/components/molecules/header/mega-menu/MegaMenu'

export default {
  components: { MegaMenu },
  props: {
    id: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: null
    },
    menuButtonTabIndex: {
      type: Number,
      default: 0
    },
    label: {
      type: String,
      default: null
    },
    url: {
      type: String,
      default: null
    },
    urlObject: {
      type: Object,
      default: null
    },
    items: {
      type: Array,
      default: null
    },
    templates: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      open: false,
      hoverable: true,
      hoverTimer: 0
    }
  },
  computed: {
    ...mapState({
      currentInterStore: state => state.international && state.international.currentInterStore,
      filters: state => state.listing && state.listing.filters,
      readyToTrack: state => state.gtm && state.gtm.readyToTrack
    }),
    returnUrl () {
      return generatePathWithQueryParams(this.urlObject)
    }
  },
  watch: {
    readyToTrack () {
      this.startTrackingViewPromotion()
    }
  },
  mounted () {
    // Avoid focus on content link
    if (this.$refs.content) {
      const links = this.$refs.content.querySelectorAll('a');
      [...links].forEach((link) => {
        link.setAttribute('tabindex', -1)
      })
    }
    if (this.readyToTrack) {
      this.startTrackingViewPromotion()
    }
  },
  methods: {
    startTrackingViewPromotion () {
      // if (this.templates?.length && this.readyToTrack) {
      //   const name = this.id + '_menu_template'
      //   this.$gtm.trackEvent(
      //     createTrackPromotionEvent('view_promotion', {
      //       name,
      //       slot: name,
      //       version: this.templates[0].creativeVersion
      //     })
      //   )
      // }
    },
    scrollToTop (to) {
      const routePath = this.$route.path
      if (!routePath === to) {
        return
      }

      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    },
    close () {
      this.setMenuClick(true)
      this.hoverOff()
    },
    reinitFilterAndRedirect () {
      this.scrollToTop(this.url)

      if ((this.$route.path === this.url) && (Object.keys(this.filters).length > 0)) {
        this.reinitFilter(true)
      }
      if (this.$route.path === this.url) {
        this.$nuxt.$loading.start()
        this.setReloadAsyncData(true)
      } else {
        this.setMenuClick(true)
      }
    },
    ...mapActions({
      reinitFilter: 'listing/reinitFilter',
      setReloadAsyncData: 'listing/setReloadAsyncData',
      setMenuClick: 'transitionPage/setMenuClick'
    }),
    toggle () {
      this.open = !this.open
      this.hoverable = !this.open
      clearTimeout(this.hoverTimer)
    },
    navigationHide () {
      this.open = false
      this.hoverable = true
      clearTimeout(this.hoverTimer)
    },
    hoverOn () {
      if (!this.hoverable) {
        return
      }
      this.open = true
      clearTimeout(this.hoverTimer)
    },
    hoverOff () {
      if (!this.hoverable) {
        return
      }
      clearTimeout(this.hoverTimer)
      this.hoverTimer = setTimeout(() => {
        this.open = false
      }, 150)
    }
  }
}
</script>
<style lang="scss" scoped>
.c-header-nav-item {
  $c: &;

  // stylelint-disable-next-line selector-class-pattern
  .c-header--white &, // stylelint-disable-next-line selector-class-pattern
  .c-header--fixed & {
    & > a,
    & > button {
      color: $grey-300 !important;

      > a {
        color: $grey-300 !important;
      }
    }

    &#{$c}--promo > a {
      color: $primary !important;

      &::after {
        background-color: $primary !important;
      }
    }
  }

  margin-bottom: 0;
  list-style: none;

  &.u-visible {
    > a,
    > button {
      &::after {
        right: 0;
        left: 0;
      }
    }
    #{$c}__sub {
      z-index: 20;
      max-height: 100vh;
    }
  }

  > a,
  > button {
    position: relative;
    display: block;
    padding: 1.5rem 1.1rem;
    margin: 0;
    color: $white;
    cursor: pointer;
    font-size: $font-size-sm;
    font-weight: 500;
    text-decoration: none;
    text-transform: uppercase;
    transition: none;

    > a {
      color: $white;
      text-decoration: none;
      transition: none;
    }

    &::after {
      position: absolute;
      z-index: 21;
      top: 100%;
      right: 51%;
      left: 51%;
      height: 0.4rem;
      background-color: $grey-300;
      content: "";
      transition: $default-transition;
    }

    &:hover {
      &::after {
        right: 0;
        left: 0;
      }
    }
  }

  &__sub {
    position: absolute;
    z-index: 1;
    right: 0;
    left: 0;
    overflow: hidden;
    max-height: 0;
    background-color: $white;
    transition: all ease-in-out 0.3s;

    // stylelint-disable-next-line selector-class-pattern
    .c-header--open & {
      transition: all 0s;
    }
  }
}
</style>
