import {
  validateGiftCard as validateGiftCardApi
} from '../../api'

export const validateGiftCard = ({
  giftCardCode,
  headers,
  instance
} = {}) => {
  return validateGiftCardApi({ giftCardCode, headers, instance })
}
