import DataStructure from '@/models/DataStructure'
import Basket from '@/models/Basket'
import CheckoutCarrierV2 from '@/models/CheckoutCarrierV2'
import RelayPointResponse from '@/models/RelayPointResponse'
import Address from '@/models/Address'
import Payment from '@/models/Payment'
import Voucher from '@/models/Voucher'
import Credit from '@/models/Credit'
export default class CheckoutOrderV2 extends DataStructure {
  constructor ({
    basket,
    carrier,
    relayPoint = null,
    shippingAddress = null,
    payment,
    vouchers = null,
    credits = null,
    affiliation = null,
    priceToPay,
    gift = null,
    device,
    flags = null
  }) {
    super()
    this.basket = this.getBasket(basket)
    this.carrier = this.getCheckoutCarrier(carrier)
    this.relayPoint = this.isset(relayPoint) ? this.getRelayPoint(relayPoint) : null
    this.shippingAddress = this.isset(shippingAddress) ? this.getShippingAddress(shippingAddress) : null
    this.payment = this.getPayment(payment)
    this.vouchers = this.isset(vouchers) ? this.getVouchers(vouchers) : null
    this.credits = this.isset(credits) ? this.getCredits(credits) : null
    this.affiliation = this.isset(affiliation) ? this.convertToNumber(affiliation) : null
    this.priceToPay = this.convertToNumber(priceToPay)
    this.gift = this.isset(gift) ? this.convertToBoolean(gift) : null
    this.device = this.convertToNumber(device)
    this.flags = this.isset(flags) ? this.getFlags(flags) : null
  }

  getBasket (value) {
    if (!value) {
      throw new Error('Basket cannot be null nor undefined')
    }
    const basket = new Basket(value)
    return basket.getApiObject()
  }

  getCheckoutCarrier (carrier) {
    if (!carrier) {
      throw new Error('Carrier cannot be null nor undefined')
    }
    const checkoutCarrier = new CheckoutCarrierV2(carrier)
    return checkoutCarrier.getApiObject()
  }

  getRelayPoint (value) {
    if (!value) {
      return null
    }
    const relayPoint = new RelayPointResponse(value)
    return relayPoint.getApiObject()
  }

  getShippingAddress (value) {
    if (!value) {
      return null
    }
    const shippingAddress = new Address(value)
    return shippingAddress.getApiObject()
  }

  getPayment (value) {
    if (!value) {
      throw new Error('Payment cannot be null nor undefined')
    }
    const payment = new Payment(value)
    return payment.getApiObject()
  }

  getVouchers (vouchers) {
    if (!vouchers || !vouchers.length) {
      return null
    }
    return vouchers.map((voucher) => {
      const voucherObject = new Voucher(voucher)
      return voucherObject.getApiObject()
    })
  }

  getCredits (creditsList) {
    if (!creditsList || !creditsList.length) {
      return null
    }
    return creditsList.map((creditItem) => {
      const credit = new Credit(creditItem)

      return credit.getApiObject()
    })
  }

  getFlags (values) {
    if (!values || !values.length) {
      return null
    }
    return values.map(item => this.convertToString(item))
  }
}
