import {
  getAvailablePayments as getAvailablePaymentsApi,
  getPaymentsVouchersAndCredits as getPaymentsVouchersAndCreditsApi,
  isOrderCreated as isOrderCreatedApi,
  commitPassiveCheckout as commitPassiveCheckoutApi,
  commitActiveCheckoutSession as commitActiveCheckoutSessionApi,
  startActiveCheckoutSession as startActiveCheckoutSessionApi
} from '@/api/secure/checkout'

import CheckoutOrderV2 from '@/models/CheckoutOrderV2'
import PaymentRequirementRequest from '@/models/PaymentRequirementRequest'
import PaymentEntity from '@/models/PaymentEntity'

export const getAvailablePayments = ({
  countryIsoCode,
  amount,
  headers,
  instance
} = {}) => {
  return getAvailablePaymentsApi({
    countryIsoCode,
    amount,
    headers,
    instance
  })
}

export const getPaymentsVouchersAndCredits = ({
  amount,
  headers,
  instance
} = {}) => {
  return getPaymentsVouchersAndCreditsApi({
    amount,
    headers,
    instance
  })
}

export const isOrderCreated = ({
  tpeReferenceId,
  headers,
  instance
} = {}) => {
  return isOrderCreatedApi({
    tpeReferenceId,
    headers,
    instance
  })
}

export const startActiveCheckoutSession = ({
  paymentEntity,
  headers,
  instance
} = {}) => {
  const paymentEntityObject = new PaymentEntity(paymentEntity)

  return startActiveCheckoutSessionApi({
    paymentEntityObject: paymentEntityObject.getApiObject(),
    headers,
    instance
  })
}

export const commitActiveCheckoutSession = ({
  token,
  checkoutOrder,
  paymentRequirement,
  headers,
  instance
} = {}) => {
  const checkoutOrderObject = new CheckoutOrderV2(checkoutOrder)
  const paymentRequirementObject = new PaymentRequirementRequest(paymentRequirement)

  return commitActiveCheckoutSessionApi({
    token,
    checkoutOrder: checkoutOrderObject.getApiObject(),
    paymentRequirement: paymentRequirementObject.getApiObject(),
    headers,
    instance
  })
}

export const commitPassiveCheckout = ({
  checkoutOrder,
  headers,
  instance
} = {}) => {
  const checkoutOrderObject = new CheckoutOrderV2(checkoutOrder)

  return commitPassiveCheckoutApi({
    checkoutOrder: checkoutOrderObject.getApiObject(),
    headers,
    instance
  })
}
