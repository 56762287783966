import DataStructure from './DataStructure'

export default class MailContactRequest extends DataStructure {
  constructor ({
    customerLastName,
    customerFirstName,
    customerEmail,
    message,
    reasonId,
    orderId = null
  }) {
    super()
    this.customerLastName = this.convertToString(customerLastName)
    this.customerFirstName = this.convertToString(customerFirstName)
    this.customerEmail = this.convertToString(customerEmail)
    this.message = this.convertToString(message)
    this.reasonId = this.convertToInteger(reasonId)
    this.orderId = this.isset(orderId) ? this.convertToInteger(orderId) : null
  }
}
