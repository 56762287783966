import DataStructure from './DataStructure'

export default class PaymentRequirementRequest extends DataStructure {
  constructor ({
    transactionId = null,
    currency = null,
    amount = null,
    frontVersion = null,
    oneClick = null,
    cardToken = null,
    javaEnabled = null,
    javascriptEnabled = null,
    browserLanguage = null,
    browserColorDepth = null,
    browserScreenHeight = null,
    browserScreenWidth = null,
    deviceFingerprint = null,
    timezone = null,
    mobileApp = null,
    userAgent = null,
    urlAccept = null,
    urlDecline = null,
    urlPending = null,
    urlException = null,
    urlCancel = null
  }) {
    super()
    this.transactionId = this.convertToString(transactionId)
    this.currency = this.convertToString(currency)
    this.amount = this.convertToNumber(amount)
    this.frontVersion = this.convertToNumber(frontVersion)
    this.oneClick = this.convertToBoolean(oneClick)
    this.cardToken = this.convertToString(cardToken)
    this.javaEnabled = this.convertToBoolean(javaEnabled)
    this.javascriptEnabled = this.convertToBoolean(javascriptEnabled)
    this.browserLanguage = this.convertToString(browserLanguage)
    this.browserColorDepth = this.convertToNumber(browserColorDepth)
    this.browserScreenHeight = this.convertToNumber(browserScreenHeight)
    this.browserScreenWidth = this.convertToNumber(browserScreenWidth)
    this.deviceFingerprint = this.isset(deviceFingerprint) ? this.convertToString(deviceFingerprint) : null
    this.timezone = this.convertToString(timezone)
    this.mobileApp = this.convertToBoolean(mobileApp)
    this.userAgent = this.isset(userAgent) ? this.convertToString(userAgent) : null
    this.urlAccept = this.isset(urlAccept) ? this.convertToString(urlAccept) : null
    this.urlDecline = this.isset(urlDecline) ? this.convertToString(urlDecline) : null
    this.urlPending = this.isset(urlPending) ? this.convertToString(urlPending) : null
    this.urlException = this.isset(urlException) ? this.convertToString(urlException) : null
    this.urlCancel = this.isset(urlCancel) ? this.convertToString(urlCancel) : null
  }
}
