import {
  getCmsPage as getCmsPageApi
} from '../api'

export const getCmsPage = ({
  pageKey,
  headers,
  instance
} = {}) => {
  return getCmsPageApi({
    pageKey,
    headers,
    instance
  })
}
