export const state = () => ({
  menu: null,
  footer: null,
  error: false,
  boSettings: null,
  notifiedLowStock: false
})

export const actions = {
  getMenu ({ commit }, { menuSections }) {
    commit('SET_MENU', menuSections)
  },

  getFooter ({ commit }, { footerItems }) {
    commit('SET_FOOTER', footerItems)
  },

  getBoSettings ({ commit }, settings) {
    commit('SET_BO_SETTINGS', settings)
  },

  toggleNotifiedLowStock ({ commit }) {
    commit('TOGGLE_NOTIFIED_LOW_STOCK')
  }
}

export const mutations = {

  SET_MENU (state, payload) {
    state.menu = [...payload]
  },

  SET_FOOTER (state, payload) {
    state.footer = [...payload]
  },

  SET_BO_SETTINGS (state, payload) {
    state.boSettings = payload
  },

  SET_MENU_LIFETIME_START (state, payload) {
    state.menuLifetimeStart = payload
  },

  SET_ERROR (state) {
    state.error = true
  },

  TOGGLE_NOTIFIED_LOW_STOCK (state) {
    state.notifiedLowStock = !state.notifiedLowStock
  }
}
