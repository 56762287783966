import DataStructure from './DataStructure'

export default class Score extends DataStructure {
  constructor ({
    id = null,
    value = null,
    name = null
  }) {
    super()
    this.id = this.isset(id) ? this.convertToNumber(id) : null
    this.value = this.isset(value) ? this.convertToNumber(value) : null
    this.name = this.isset(name) ? this.convertToString(name) : null
  }
}
