import DataStructure from './DataStructure'
export default class RelayPointAddress extends DataStructure {
  constructor ({
    address1 = null,
    zipCode = null,
    city = null,
    countryCode = null,
    latitude = null,
    longitude = null
  }) {
    super()
    this.address1 = this.isset(address1) ? this.convertToString(address1) : null
    this.zipCode = this.isset(zipCode) ? this.convertToString(zipCode) : null
    this.city = this.isset(city) ? this.convertToString(city) : null
    this.countryCode = this.isset(countryCode) ? this.convertToString(countryCode) : null
    this.latitude = this.isset(latitude) ? this.convertToNumber(latitude) : null
    this.longitude = this.isset(longitude) ? this.convertToNumber(longitude) : null
  }
}
