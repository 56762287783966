import DataStructure from './DataStructure'
import Score from './Score'
export default class ProductScore extends DataStructure {
  constructor ({
    id = null,
    score = null,
    text = null,
    customerPseudo = null,
    created = null,
    store = null
  }) {
    super()
    this.id = this.isset(id) ? this.convertToNumber(id) : null
    this.score = this.isset(score) ? this.getScore(score) : null
    this.text = this.isset(text) ? this.convertToString(text) : null
    this.customerPseudo = this.isset(customerPseudo) ? this.convertToString(customerPseudo) : null
    this.created = this.isset(created) ? this.convertToString(created) : null
    this.store = this.isset(store) ? this.convertToString(store) : null
  }

  getScore (value) {
    if (!value) {
      return null
    }
    const score = new Score(value)
    return score.getApiObject()
  }
}
