<template>
  <div
    class="c-dropdown-menu"
    :class="{
      'c-dropdown-menu--active' : hasActiveClass && open || forceshow,
      'c-dropdown-menu--basket' : basket,
      'c-dropdown-menu--lang' : lang,
      'c-dropdown-menu--new-product' : newProduct,
      'c-dropdown-menu--inter': isInternationalDisplay
    }"
  >
    <button
      :id="`${id}__label`"
      ref="button"
      role="button"
      aria-haspopup="true"
      :aria-controls="`${id}__region`"
      :aria-expanded="open.toString()"
      class="c-dropdown-menu__button"
      data-cy="header-tool--account"
      @click="toggle"
      @mouseenter="hoverOn"
      @mouseleave="hoverOff"
    >
      <slot name="button" />
    </button>

    <div
      v-show="open || forceshow"
      :id="`${id}__region`"
      ref="content"
      role="menu"
      :aria-labelledby="`${id}__label`"
      class="c-dropdown-menu__panel"
      @mouseenter="hoverOn"
      @mouseleave="hoverOff"
    >
      <slot :hide="navigationHide" />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  props: {
    id: {
      type: String,
      required: true
    },
    basket: {
      type: Boolean,
      default: false
    },
    lang: {
      type: Boolean
    },
    forceshow: {
      type: Boolean,
      default: false
    },
    newProduct: {
      type: Boolean,
      default: false
    },
    hasActiveClass: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      open: false,
      hoverable: true,
      hoverTimer: 0,
      activeOptionIndex: -1,
      deviceTypeDesktop: this.$ua.deviceType() === 'pc' || this.$ua.isFromTablet()
    }
  },
  computed: {
    ...mapGetters({
      isInternationalDisplay: 'international/isInternationalDisplay'
    })
  },
  mounted () {
    if (this.$refs.content) {
      const links = this.$refs.content.querySelectorAll('a, button');
      [...links].forEach((link) => {
        link.setAttribute('tabindex', -1)
      })
    }
  },

  methods: {
    toggle () {
      if (!this.deviceTypeDesktop) {
        return
      }
      this.open = !this.open
      this.hoverable = !this.open
      clearTimeout(this.hoverTimer)
    },
    navigationHide () {
      this.activeOptionIndex = -1
      this.open = false
      this.hoverable = true
      clearTimeout(this.hoverTimer)
    },
    hoverOn () {
      if (!this.deviceTypeDesktop || !this.hoverable) {
        return
      }
      this.$emit('hoverOn')
      this.open = true
      clearTimeout(this.hoverTimer)
    },
    hoverOff () {
      if (!this.deviceTypeDesktop || !this.hoverable) {
        return
      }
      clearTimeout(this.hoverTimer)
      this.hoverTimer = setTimeout(() => {
        this.open = false
        this.$emit('hoverOff')
      }, 150)
    }
  }
}
</script>

<style lang="scss" scoped>
.c-dropdown-menu {
  $c: &;

  &--active {
    > #{$c}__button {
      &::after {
        position: absolute;
        top: 100%;
        left: 50%;
        width: 0;
        height: 0;
        border-width: 0 1rem 1rem;
        border-style: solid;
        border-color: transparent transparent $grey-200 transparent;
        content: "";
        transform: translateX(-50%);
        transform-origin: center center;

        @include mq($until: tablet) {
          top: calc(100% + 0.4rem);
        }
      }

      &::before {
        position: absolute;
        top: 100%;
        left: 50%;
        width: 5rem;
        height: 2rem;
        content: "";
        transform: translateX(-50%);
      }
    }
  }

  &--lang {
    #{$c}__panel {
      width: 36rem;
    }
  }

  &--basket {
    > #{$c}__button {
      &::after {
        border-color: transparent transparent $grey-800 transparent !important;
      }
    }

    #{$c}__panel {
      width: 45rem;
    }
  }

  &--new-product {
    > #{$c}__button {
      &::after {
        border-color: transparent transparent $accent-dark transparent !important;
      }
    }
  }

  &--inter {
    #{$c}__panel {
      top: calc(100% - 3.3rem);
    }
  }

  &__panel {
    position: absolute;
    top: calc(100% - 2.8rem);
    right: 1.2rem;
    overflow: hidden;
    width: 35.8rem;
    background-color: $white;

    @include mq($until: tablet) {
      top: 100%;
      right: 0;
      width: 100vw !important;
    }
  }

  &__button {
    position: relative;
    display: flex;
    width: 2rem;
    height: 2.8rem;
    align-items: center;
    justify-content: center;
    padding: 0.4rem 0;
    border: none;
    background-color: transparent;
    color: $white;
    transition: none;
  }
}
</style>
