import DataStructure from './DataStructure'

export default class Payment extends DataStructure {
  constructor ({
    id = null,
    rank = null,
    picture = null,
    mobilePicture = null,
    type = null,
    name = null,
    description = null
  }) {
    super()
    this.id = this.isset(id) ? this.convertToNumber(id) : null
    this.rank = this.isset(rank) ? this.convertToNumber(rank) : null
    this.picture = this.isset(picture) ? this.convertToString(picture) : null
    this.mobilePicture = this.isset(mobilePicture) ? this.convertToString(mobilePicture) : null
    this.type = this.isset(type) ? this.convertToString(type) : null
    this.name = this.isset(name) ? this.convertToString(name) : null
    this.description = this.isset(description) ? this.convertToString(description) : null
  }
}
