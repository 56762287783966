import DataStructure from './DataStructure'

export default class PaymentEntity extends DataStructure {
  constructor ({
    amount = null,
    civility = null,
    firstname = null,
    lastname = null,
    street = null,
    streetDetails = null,
    city = null,
    zipcode = null,
    countryCode = null
  }) {
    super()
    this.amount = this.isset(amount) ? this.convertToNumber(amount) : null
    this.civility = this.isset(civility) ? this.convertToNumber(civility) : null
    this.firstname = this.isset(firstname) ? this.convertToString(firstname) : null
    this.lastname = this.isset(lastname) ? this.convertToString(lastname) : null
    this.street = this.isset(street) ? this.convertToString(street) : null
    this.streetDetails = this.isset(streetDetails) ? this.convertToString(streetDetails) : null
    this.city = this.isset(city) ? this.convertToString(city) : null
    this.zipcode = this.isset(zipcode) ? this.convertToString(zipcode) : null
    this.countryCode = this.isset(countryCode) ? this.convertToString(countryCode) : null
  }
}
