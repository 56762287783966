import { api } from './instance'

export const getBrandSummary = (options = {}) => {
  const { version, headers, instance } = options
  return (instance || api).get(
    `/summary-items/brand/${version}`,
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getArtistSummary = (options = {}) => {
  const {
    artistTypeId,
    version,
    headers,
    instance
  } = options
  return (instance || api).get(
    `/summary-items/artist/${artistTypeId}/${version}`,
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}
