import { APPLE_CLIENT_ID } from '@/config.js'
import ApiFront from '@/services/legacy/agent'

const api = new ApiFront.SocialConnectApi()

export function facebookConnect (accessToken, userID) {
  const facebookPayload = new ApiFront.FacebookConnect(accessToken, userID)
  return new Promise((resolve, reject) => {
    api.facebookConnect(facebookPayload, (error, _, response) => {
      if (error) {
        reject(error)
      } else {
        resolve(response.body)
      }
    })
  })
}

export function appleConnect (code, firstName, lastName) {
  const applePayload = new ApiFront.AppleConnect(code)
  applePayload.firstName = firstName
  applePayload.lastName = lastName
  applePayload.clientId = APPLE_CLIENT_ID

  return new Promise((resolve, reject) => {
    api.appleSignIn(applePayload, (error, _, response) => {
      if (error) {
        reject(error)
      } else {
        resolve(response.body)
      }
    })
  })
}
