import ApiFront from '@/services/legacy/agent'

const api = new ApiFront.AuthenticationApi()

export function login (username, password) {
  const credentials = new ApiFront.UserCredentials(username, password)
  return new Promise((resolve, reject) => {
    api.login(credentials, (error, _, response) => {
      if (error) {
        reject(error)
      } else {
        resolve(response.body)
      }
    })
  })
}

export function refresh (refreshToken) {
  const token = new ApiFront.RefreshToken(refreshToken)
  return new Promise((resolve, reject) => {
    api.refresh(token, (error, _, response) => {
      if (error) {
        reject(error)
      } else {
        resolve(response.body)
      }
    })
  })
}
