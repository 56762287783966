import { mapActions, mapState, mapGetters } from 'vuex'
import {
  generatePageTypeBasedOnRouteNameOrListingType,
  generatePageTitleForGtmTracking,
  generatePageCSBA,
  cleanGtmDataLayer
} from '@/helpers/gtmHelper'
import { generateUUID } from '@/helpers/generateUUID'
export default {
  methods: {
    ...mapActions({
      sendDataToGTM: 'gtm/sendDataToGTM',
      sendLocaleToGTM: 'gtm/sendLocaleToGTM',
      sendPageData: 'gtm/sendPageData',
      setPageType: 'gtm/setPageType',
      setPageCSBA: 'gtm/setPageCSBA',
      setPageTitle: 'gtm/setPageTitle',
      setPageUrl: 'gtm/setPageUrl',
      setUUID: 'gtm/setUUID',
      setProductData: 'gtm/setProductData',
      setDefaultClientAddresse: 'gtm/setDefaultClientAddresse',
      setFirstPageView: 'gtm/setFirstPageView'
    }),
    startTrackingPageView () {
      if (this.readyToTrack) {
        this.$gtm.trackEvent(cleanGtmDataLayer)
        if (this.firstPageView) {
          this.setFirstPageView(false)
        }
        this.sendDataToGTM({
          gtm: this.$gtm,
          eventName: 'lbo_page_view'
        })
      }
    },
    setSessionID () {
      if (!this.sessionIDUpdate) {
        const sessionIDCookie = this.$cookies.get('lboSessionID') || null
        if (!sessionIDCookie) {
          const uuid = generateUUID()
          this.$cookies.set('lboSessionID', uuid, { path: '/', maxAge: process.env.GTM_SESSION_ID_MAXAGE })
          this.setUUID(uuid)
        } else {
          this.$cookies.set('lboSessionID', sessionIDCookie, { path: '/', maxAge: process.env.GTM_SESSION_ID_MAXAGE })
          this.setUUID(sessionIDCookie)
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      getPageDataType: 'gtm/getPageDataType',
      getProductData: 'gtm/getProductData'
    }),
    ...mapState({
      sessionIDUpdate: state => state.gtm && state.gtm.sessionIDUpdate,
      readyToTrack: state => state.gtm && state.gtm.readyToTrack,
      firstPageView: state => state.gtm && state.gtm.firstPageView,
      listingTypeGtm: state => state.listing && state.listing.type,
      defaultClientData: state => state.gtm.defaultClientData,
      defaultClientAddresse: state => state.gtm.defaultClientAddresse
    })
  },
  watch: {
    readyToTrack () {
      this.startTrackingPageView()
    }
  },
  created () {
    this.sendLocaleToGTM({
      gtm: this.$gtm,
      defaultInterStore: this.$store.state.international.currentInterStore
    })
  },
  // do not put this in created hook as it broke page reloading in tunnel pages
  mounted () {
    this.setPageTitle(generatePageTitleForGtmTracking(this.$store.state.seo))
    this.setPageUrl(this.$route.fullPath)
    this.setPageType(generatePageTypeBasedOnRouteNameOrListingType(this.listingTypeGtm, this.$route.name))
    this.setPageCSBA(generatePageCSBA(this.$store.state.globalPageInfo, this.listingTypeGtm, this.getProductData))
    // Si on est sur une page produit après avoir fait le set
    // du pageCSBA on vient clean les productData
    if (this.getProductData) {
      this.setProductData(null)
    }
    // On envoie qu'une fois le page data au premier load de la page pour set
    // la page_data dans le dataLayer
    // Permet lors du concente d'avoir les bonnes infos dans l'objet pour charger les tag FB pixel etc...
    this.setSessionID()
    if (!this.readyToTrack) {
      this.sendPageData({
        gtm: this.$gtm
      })
    }
    if (this.readyToTrack) {
      this.startTrackingPageView()
    }
  }
}
