import {
  getBrandSummary as getBrandSummaryApi,
  getArtistSummary as getArtistSummaryApi
} from '@/api/summary'

export const getBrandSummary = ({
  headers,
  version = 'v3',
  instance
} = {
  headers: null,
  version: 'v3',
  instance: null
}) => {
  return getBrandSummaryApi({
    version,
    headers,
    instance
  })
}

export const getArtistSummary = ({
  artistTypeId,
  version = 'v3',
  instance,
  headers
} = {}) => {
  return getArtistSummaryApi({
    artistTypeId,
    version,
    headers,
    instance
  })
}
