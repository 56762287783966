import {
  getShippings as getShippingsApi,
  getShippingRelayPoints as getShippingRelayPointsApi
} from '../../api'
import { buildCollectionParam } from '@/utils/buildCollectionParam'

export const getShippings = ({
  productIds,
  price,
  weight,
  countryCode,
  promotionCode,
  headers,
  instance
} = {}) => {
  return getShippingsApi({
    productIds: [buildCollectionParam(productIds, 'pipes')],
    price,
    weight,
    countryCode,
    promotionCode,
    headers,
    instance
  })
}

export const getShippingRelayPoints = ({
  productIds,
  countryCode,
  weight,
  zipCode,
  city,
  address,
  origin,
  id,
  headers,
  instance
} = {}) => {
  return getShippingRelayPointsApi({
    productIds: [buildCollectionParam(productIds, 'pipes')],
    countryCode,
    weight,
    zipCode,
    city,
    address,
    origin,
    id,
    headers,
    instance
  })
}
