import DataStructure from './DataStructure'

export default class CreditStatus extends DataStructure {
  constructor ({
    id = null,
    name = null
  }) {
    super()
    this.id = this.isset(id) ? this.convertToNumber(id) : null
    this.name = this.isset(name) ? this.convertToString(name) : null
  }
}
