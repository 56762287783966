import DataStructure from './DataStructure'
import Money from './Money'

export default class CheckoutCarrierV2 extends DataStructure {
  constructor ({
    price = null,
    shippingDate,
    deliveryDate,
    carrierId
  }) {
    super()
    this.price = this.isset(price) ? this.getPrice(price) : null
    this.shippingDate = this.convertToString(shippingDate)
    this.deliveryDate = this.convertToString(deliveryDate)
    this.carrierId = this.convertToNumber(carrierId)
  }

  getPrice (price) {
    if (!price) {
      return null
    }
    const money = new Money(price)
    return money.getApiObject()
  }

  filterNullValues () {
    const filteredOptions = {}

    for (const key in this) {
      const value = this[key]

      if (value !== null && value !== undefined) {
        filteredOptions[key] = value
      }
    }

    return filteredOptions
  }

  getValues () {
    return this.filterNullValues()
  }
}
