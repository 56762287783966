export const ALGOLIA_CLIENT_ID = process.env.ALGOLIA_CLIENT_ID
export const ALGOLIA_INDEX_PREFIX = process.env.ALGOLIA_INDEX_PREFIX

export const ALGOLIA_API_KEYS = {
  laboutiqueofficielle: process.env.ALGOLIA_API_KEY_LABOUTIQUEOFFICIELLE,
  ryses_es: process.env.ALGOLIA_API_KEY_RYSES_ES,
  ryses_it: process.env.ALGOLIA_API_KEY_RYSES_IT,
  ryses_be: process.env.ALGOLIA_API_KEY_RYSES_BE,
  ryses_de: process.env.ALGOLIA_API_KEY_RYSES_DE,
}

export const NAME_VALUE_SEPARATOR = '~~'
export const ITEM_WITH_PARAM_SEPARATOR = '--'
export const ITEM_SEPARATOR = '~'

export const TYPE = {
  BESTOFFERS1: 'LISTING_BESTOFFERS1',
  BESTOFFERS2: 'LISTING_BESTOFFERS2',
  BRAND: 'LISTING_BRAND',
  ARTIST: 'LISTING_ARTIST',
  NOVELTY: 'LISTING_NOVELTIES',
  TOPSALES: 'LISTING_TOP_SALES',
  PROMO: 'LISTING_PROMOTIONS',
  CATEGORY: 'LISTING_CATEGORY',
  OUTLET: 'LISTING_OUTLET',
  FLASH: 'LISTING_FLASH_SALE',
  SALES: 'LISTING_SALES',
  SEARCH: 'search'
}
export const SOLDES_PARAM_TRANSLATE = {
  laboutiqueofficielle: 'soldes',
  ryses_es: 'rebajas',
  ryses_it: 'saldi',
  ryses_be: 'solden',
  ryses_de: 'sale',
}
export const LISTING_TYPE = {
  ARTIST_BRAND: '_brand_artist_relevancy_default',
  ARTIST_BRAND_SALES: '_brand_artist_relevancy_sales',
  BEST_OFFERS: '_best_offers',
  NOVELTY: '_novelty',
  DEFAULT: '_relevancy_default',
  FLASH: '_flash_sale_relevancy',
  SORT_ASC: '_price_asc',
  SORT_DESC: '_price_desc',
  SORT_NOVELTY: '_novelty',
  SEARCH: '_search',
  SEARCH_RESULTS: '_search_relevancy'
}

const makeRegex = function (regex, flags = 'i') {
  return new RegExp(regex, flags)
}

export const ALGOLIAFACETS = {
  matcheUrl: {
    categories: makeRegex(`${NAME_VALUE_SEPARATOR}[0-9]{1,5}${NAME_VALUE_SEPARATOR}[a-z]`), // ~~100~~Accessoires
    sizes: makeRegex(`${NAME_VALUE_SEPARATOR}[0-9]{6}${NAME_VALUE_SEPARATOR}[a-z0-9./ ]{1,25}~~[a-z]{3,5}`), // ~~100300~~39 1/3~~shoes
    colors: makeRegex(`${NAME_VALUE_SEPARATOR}#[0-9a-f]{3,6}`) // Jaune~~#fff600
  },
  names: {
    ARTIST_NAME: 'artists',
    CATEGORIES: 'categories',
    BRANDS: 'brands',
    SIZES: 'sizes',
    COLORS: 'colors',
    GENDERS: 'sexe',
    PRICES: 'prices',
    PROMOTION: 'promotions',
    MATERIALS: 'materials',
    RANGES: 'ranges',
    ECO_FRIENDLY: 'eco-label'
  },
  global: {
    artists: {
      id: 'artists.artistId',
      name: 'artists.name'
    },
    brand: {
      id: 'brand.brandId',
      name: 'brand.name'
    },
    category: {
      id: 'category.categoryId',
      parentId: 'category.parentId'
    },
    ranges: {
      id: 'ranges.rangeId',
      name: 'ranges.name',
      aggregate: 'ranges.aggregate'
    },
    size: {
      name: 'sizes.size',
      ranked: {
        name: 'sizes.rankedName',
        nameWithType: 'sizes.rankedNameWithType'
      }
    },
    promotion: 'promotion.percent'
  }
}
export function generateDynamicFacetName (locale, name) {
  let dynamicName
  switch (name) {
    case 'CATEGORIES' :
      dynamicName = `category.hierarchy.${locale}.lvl0`
      break
    case 'COLORS' :
      dynamicName = `characteristics.${locale}.color`
      break
    case 'MATERIALS' :
      dynamicName = `characteristics.${locale}.material`
      break
    case 'GENDERS' :
      dynamicName = `gender.${locale}`
      break
    case 'PRICE' :
      dynamicName = `prices.${locale}.price`
      break
    case 'ECO_FRIENDLY' :
      dynamicName = `marketLabels.ECO_FRIENDLY.${locale}`
      break
    case 'EXCLU' :
      dynamicName = `marketLabels.EXCLUSIVITY.${locale}`
      break
  }
  return dynamicName
}
export function generateFilterParams (name, currentInterStore) {
  let filterParams
  switch (name) {
    case 'ARTIST_NAME':
      filterParams = {
        FACET_NAME: ALGOLIAFACETS.global.artists.name,
        NAME: ALGOLIAFACETS.names.ARTIST_NAME,
        SEARCHABLE: false,
        LABEL: 'components.molecules.listing.filters.artists.title',
        KEY: 'ARTIST_NAME'
      }
      break
    case 'CATEGORIES':
      filterParams = {
        FACET_NAME: `category.hierarchy.${currentInterStore.langCode}.lvl0`,
        NAME: ALGOLIAFACETS.names.CATEGORIES,
        MATCH: ALGOLIAFACETS.matcheUrl.categories,
        USAGE: 'search',
        SEARCHABLE: false,
        LABEL: 'components.organisms.listing.search.category',
        KEY: 'CATEGORIES'
      }
      break
    case 'BRANDS':
      filterParams = {
        FACET_NAME: ALGOLIAFACETS.global.brand.name,
        NAME: ALGOLIAFACETS.names.BRANDS,
        USAGE: 'all',
        SEARCHABLE: true,
        LABEL: 'components.molecules.listing.filters.brands.title',
        KEY: 'BRANDS'
      }
      break
    case 'SIZES':
      filterParams = {
        FACET_NAME: ALGOLIAFACETS.global.size.ranked.nameWithType,
        MATCH: ALGOLIAFACETS.matcheUrl.colors,
        NAME: ALGOLIAFACETS.names.SIZES,
        SEARCHABLE: false,
        USAGE: 'all',
        KEY: 'SIZES'
      }
      break
    case 'COLORS':
      filterParams = {
        FACET_NAME: `characteristics.${currentInterStore.langCode}.color`,
        MATCH: ALGOLIAFACETS.matcheUrl.colors,
        NAME: ALGOLIAFACETS.names.COLORS,
        USAGE: 'all',
        COMPONENT: 'Color',
        LABEL: 'components.molecules.listing.filters.filterColor.title',
        SEARCHABLE: false,
        KEY: 'COLORS'
      }
      break
    case 'GENDERS':
      filterParams = {
        FACET_NAME: `gender.${currentInterStore.langCode}`,
        NAME: ALGOLIAFACETS.names.GENDERS,
        USAGE: 'all',
        SEARCHABLE: false,
        LABEL: 'components.organisms.listing.search.gender',
        KEY: 'GENDERS'
      }
      break
    case 'PRICE':
      filterParams = {
        FACET_NAME: `prices.${currentInterStore.langCode}.price`,
        NAME: ALGOLIAFACETS.names.PRICES,
        SEARCHABLE: false,
        USAGE: 'all',
        KEY: 'PRICE'
      }
      break
    case 'PROMOTION':
      filterParams = {
        FACET_NAME: ALGOLIAFACETS.global.promotion,
        NAME: ALGOLIAFACETS.names.PROMOTION,
        USAGE: 'all',
        SEARCHABLE: false,
        KEY: 'PROMOTION'
      }
      break
    case 'MATERIALS':
      filterParams = {
        FACET_NAME: `characteristics.${currentInterStore.langCode}.material`,
        NAME: ALGOLIAFACETS.names.MATERIALS,
        LABEL: 'components.organisms.listing.search.material',
        SEARCHABLE: true,
        KEY: 'MATERIALS'
      }
      break
    case 'RANGES':
      filterParams = {
        FACET_NAME: ALGOLIAFACETS.global.ranges.name,
        NAME: ALGOLIAFACETS.names.RANGES,
        USAGE: 'all',
        LABEL: 'components.organisms.listing.search.range',
        SEARCHABLE: false,
        KEY: 'RANGES'
      }
      break
    case 'RANGES_AGGREGATE':
      filterParams = {
        FACET_NAME: ALGOLIAFACETS.global.ranges.aggregate,
        NAME: ALGOLIAFACETS.names.RANGES,
        USAGE: 'all',
        LABEL: 'components.organisms.listing.search.range',
        SEARCHABLE: false,
        KEY: 'RANGES'
      }
      break
    case 'ECO_FRIENDLY':
      filterParams = {
        FACET_NAME: `marketLabels.ECO_FRIENDLY.${currentInterStore.langCode}`,
        NAME: ALGOLIAFACETS.names.ECO_FRIENDLY,
        USAGE: 'all',
        SEARCHABLE: false,
        KEY: 'ECO_FRIENDLY'
      }
      break
  }
  return filterParams
}

export const ALGOLIA_ERROR_TYPES = {
  RETRY_ERROR: 'RetryError'
}
