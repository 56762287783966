export const state = () => ({
  activeSkeleton: false,
  routeTo: null,
  spacer: false,
  productThumbnailClick: false,
  bubbleClick: false,
  breadcrumbClick: false,
  menuClick: false,
  checkHeaderColor: false
})

export const actions = {
  setSpacer ({ commit }, payload) {
    commit('SET_SPACER', payload)
  },
  setCheckHeaderColor ({ commit }, payload) {
    commit('SET_CHECK_HEADER_COLOR', payload)
  },
  setActiveSkeleton ({ commit }, payload) {
    commit('SET_ACTIVE_SKELETON', payload)
  },
  setRouteTo ({ commit }, payload) {
    commit('SET_ROUTE_TO', payload)
  },
  setProductThumbnailClick ({ commit }, payload) {
    commit('SET_PRODUCT_THUMBNAIL_CLICK', payload)
  },
  setBubbleClick ({ commit }, payload) {
    commit('SET_BUBBLE_CLICK', payload)
  },
  setBreadcrumbClick ({ commit }, payload) {
    commit('SET_BREADCRUMB_CLICK', payload)
  },
  setMenuClick ({ commit }, payload) {
    commit('SET_MENU_CLICK', payload)
  }
}

export const mutations = {
  SET_CHECK_HEADER_COLOR (state, payload) {
    state.checkHeaderColor = payload
  },
  SET_SPACER (state, payload) {
    state.spacer = payload
  },
  SET_ACTIVE_SKELETON (state, payload) {
    state.activeSkeleton = payload
  },
  SET_ROUTE_TO (state, payload) {
    state.routeTo = payload
  },
  SET_PRODUCT_THUMBNAIL_CLICK (state, payload) {
    state.productThumbnailClick = payload
  },
  SET_BUBBLE_CLICK (state, payload) {
    state.bubbleClick = payload
  },
  SET_BREADCRUMB_CLICK (state, payload) {
    state.breadcrumbClick = payload
  },
  SET_MENU_CLICK (state, payload) {
    state.menuClick = payload
  }
}
