import {
  getSizeGuideByProductId as getSizeGuideByProductIdApi
} from '@/api/sizeGuide'

export const getSizeGuideByProductId = ({
  productId,
  headers,
  instance
} = {}) => {
  return getSizeGuideByProductIdApi({
    productId,
    headers,
    instance
  })
}
