export const createAccount = (options = {}) => {
  const { customerObject, headers, instance } = options
  return (instance).post(
    '/account',
    { ...customerObject },
    { headers }
  ).then(res => res.data)
    .catch((error) => {
      throw error
    })
}

export const resetPassword = (options = {}) => {
  const { email, headers, instance } = options
  return (instance).patch(
    '/account/password/reset',
    { email },
    { headers }
  ).then(res => res.data)
    .catch((error) => {
      throw error
    })
}
