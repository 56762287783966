import {
  getAccountProfile as getAccountProfileApi,
  patchAccountProfile as patchAccountProfileApi,
  getAccountCustomerAddresses as getAccountCustomerAddressesApi,
  createAddress as createAddressApi,
  patchAddress as patchAddressApi,
  deleteAddress as deleteAddressApi,
  patchPassword as patchPasswordApi,
  getAccountVouchers as getAccountVouchersApi
} from '../../api'

import Customer from '../../models/Customer'
import Address from '../../models/Address'

export const getAccountProfile = ({
  headers,
  instance
} = {}) => {
  return getAccountProfileApi({ headers, instance })
}

export const patchAccountProfile = ({
  customerObject,
  headers,
  instance
} = {}) => {
  const customer = new Customer(customerObject)

  return patchAccountProfileApi({
    customerObject: customer.getApiObject(),
    headers,
    instance
  })
}

export const getAccountCustomerAddresses = ({
  headers,
  instance
} = {}) => {
  return getAccountCustomerAddressesApi({
    headers,
    instance
  })
}

export const createAddress = ({
  addressObject,
  headers,
  instance
} = {}) => {
  const address = new Address(addressObject)
  return createAddressApi({
    addressObject: address.getApiObject(),
    headers,
    instance
  })
}

export const patchAddress = ({
  addressObject,
  headers,
  instance
} = {}) => {
  const address = new Address(addressObject)

  return patchAddressApi({
    addressObject: address.getApiObject(),
    headers,
    instance
  })
}

export const deleteAddress = ({
  addressID,
  headers,
  instance
} = {}) => {
  return deleteAddressApi({
    addressID,
    headers,
    instance
  })
}

export const patchPassword = ({
  passwordObject,
  headers,
  instance
} = {}) => {
  return patchPasswordApi({
    passwordObject,
    headers,
    instance
  })
}

export const getAccountVouchers = ({
  headers,
  instance
} = {}) => {
  return getAccountVouchersApi({
    headers,
    instance
  })
}
