import { STATIC_URL } from '@/config'
import { ALGOLIAFACETS } from '@/config/algolia/config'

export function isStaticPage (route) {
  const arrayUrl = STATIC_URL
  let staticPage = {
    static: false,
    type: null
  }
  if (route.path === '/') {
    staticPage = {
      static: true,
      type: 'home'
    }
  } else {
    arrayUrl.forEach((item) => {
      if (route.path.startsWith(item)) {
        staticPage = {
          static: true,
          type: 'other'
        }
      }
    })
  }
  return staticPage
}

export function sanitizeUrlParams (fullUrl, paramsToIgnore = ['soldes=1']) {
  const paramsInFullUrl = fullUrl.split('?').length > 1 ? fullUrl.split('?')[1].split(/\?|&(?=[^=&]+=[^&])/) : fullUrl.split('?')[1]
  let filteredParams = []

  if (paramsInFullUrl) {
    filteredParams = paramsInFullUrl.filter(param => paramsToIgnore.some((v) => {
      if (v.length === 0 || param.length === 0) {
        return false
      }
      return param.startsWith(v)
    }))
  }

  const sanitizedQuery = filteredParams.reduce((prev, curr, index) => {
    return prev.concat(`${index > 0 ? '&' : ''}${curr}`)
  }, '')

  const sanitizedFullUrl = sanitizedQuery.length ? fullUrl.split('?')[0].concat(`?${sanitizedQuery}`) : fullUrl.split('?')[0]

  return sanitizedFullUrl
}

export function isForbiddenQuery (query) {
  const whitelistParamNames = ['page', ...Object.values(ALGOLIAFACETS.names)]
  const paramValueConstraint = /^[a-z0-9~'#.\-/\s\u00C0-\u024F]*$/i

  return Object.entries(query).some(([key, value]) => {
    if (!whitelistParamNames.includes(key)) {
      return false
    }

    if (key === 'page' && !Number.isInteger(+value)) {
      return true
    }

    return !paramValueConstraint.test(value)
  })
}
