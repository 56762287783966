import { api } from './instance'

export const getSizeGuideByProductId = (options = {}) => {
  const { productId, headers, instance } = options
  return (instance || api).get(
    `/size-guide/${productId}`,
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}
