import DataStructure from './DataStructure'
import RelayPointAddress from './RelayPointAddress'
import RelayPointSchedule from './RelayPointSchedule'
export default class CheckoutOrderV2 extends DataStructure {
  constructor ({
    carrierId = null,
    logo = null,
    name = null,
    address = null,
    distance = null,
    identifier = null,
    relayType = null,
    schedules = null
  }) {
    super()
    this.carrierId = this.isset(carrierId) ? this.convertToNumber(carrierId) : null
    this.logo = this.isset(logo) ? this.convertToString(logo) : null
    this.name = this.isset(name) ? this.convertToString(name) : null
    this.address = this.isset(address) ? this.getAddress(address) : null
    this.distance = this.isset(distance) ? this.convertToNumber(distance) : null
    this.identifier = this.isset(identifier) ? this.convertToString(identifier) : null
    this.relayType = this.isset(relayType) ? this.convertToString(relayType) : null
    this.schedules = this.isset(schedules) ? this.getSchedules(schedules) : null
  }

  getAddress (value) {
    if (!value) {
      return null
    }
    const address = new RelayPointAddress(value)
    return address.getApiObject()
  }

  getSchedules (schedules) {
    if (!schedules || !schedules.length) {
      return null
    }
    return schedules.map((schedule) => {
      const scheduleObject = new RelayPointSchedule(schedule)
      return scheduleObject.getApiObject()
    })
  }
}
