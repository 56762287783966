import algoliasearch from 'algoliasearch/lite'
import algoliasearchHelper from 'algoliasearch-helper'
import {
  createWish,
  getWishList,
  deleteWish
} from '@/services/secure/wish'

import { ALGOLIA_CLIENT_ID, ALGOLIA_API_KEYS, LISTING_TYPE } from '@/config/algolia/config'

import { generateAlgoliaIndex } from '@/helpers/generateAlgoliaIndex'
import { sendSentryMessage } from '@/helpers/errorHelper'
import { algoliaErrorHandler } from '@/helpers/algoliasearchHelper'

export const state = () => ({
  products: [],
  isFirstLoad: true,
  error: false,
  productToAdd: null,
  productToAddAfterLoginOrSubscribe: null
})

export const getters = {
  nbProducts (state) {
    return state.products.length
  }
}

export const actions = {
  setproductToAddAfterLoginOrSubscribe ({ commit }, payload) {
    commit('SET_PRODUCT_TO_ADD_AFTER_LOGIN_OR_SUBSCRIBE', payload)
  },
  async getProducts ({ commit, rootState, state }, payload) {
    if (!state.isFirstLoad) { return }
    const { instance } = payload
    try {
      const algoliaIndex = rootState.international.currentInterStore.algoliaProductIndex
      const client = algoliasearch(ALGOLIA_CLIENT_ID, ALGOLIA_API_KEYS[rootState.international.currentInterStore.id])

      const response = await getWishList({
        instance
      })
      const wishlistIds = response.map(item => item.product)

      const helper = algoliasearchHelper(client, generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.DEFAULT))
      helper.addNumericRefinement('productID', '=', wishlistIds).search()

      const { hits } = await new Promise((resolve, reject) => {
        helper.on('result', ({ results }) => {
          resolve(results)
        })
        helper.on('error', (evt) => {
          reject(evt)
        })
      })

      /* Little hack to fetch products that only have stock
      ** We fetch results from Algolia and hits can't be out of stock
      ** Then we filter the wishlist accordingly to Algolia hits results */
      const finalProductList = response.filter(wish => hits.some(hit => wish.product === hit.productID))

      commit('SET_PRODUCTS', finalProductList)
    } catch (err) {
      const exception = algoliaErrorHandler(err)

      sendSentryMessage.bind(this, 'Unable to get wishlist', 'error', {
        exception,
        store: rootState.international.currentInterStore.id
      })()

      commit('SET_ERROR')
    }
  },

  async addProduct ({ commit }, payload) {
    const { instance, productId } = payload
    try {
      const wish = await createWish({
        instance,
        productId
      })
      commit('ADD_PRODUCT', wish)
    } catch (err) {
      commit('SET_ERROR')
    }
  },

  async removeProduct ({ commit, rootState }, payload) {
    const { wish, instance } = payload
    try {
      await deleteWish({
        wish,
        instance
      })
      commit('REMOVE_PRODUCT', wish)
    } catch (err) {
      commit('SET_ERROR')
    }
  },

  clearStore ({ commit }) {
    commit('CLEAR_STORE')
  },

  setProductToAdd ({ commit }, payload) {
    commit('SET_PRODUCT_TO_ADD', payload)
  }
}

export const mutations = {
  SET_PRODUCT_TO_ADD_AFTER_LOGIN_OR_SUBSCRIBE (state, payload) {
    state.productToAddAfterLoginOrSubscribe = payload
  },

  SET_PRODUCTS (state, payload) {
    state.products = [...payload]
    state.isFirstLoad = false
  },

  ADD_PRODUCT (state, wish) {
    const index = state.products.findIndex(item => item.id === wish.id)
    if (index === -1) {
      state.products = [...state.products, wish]
    }
  },

  REMOVE_PRODUCT (state, wish) {
    const products = [...state.products]
    const index = products.findIndex(item => item.id === wish.id)

    if (index > -1) {
      products.splice(index, 1)
      state.products = products
    }
  },

  SET_ERROR (state) {
    state.error = true
  },

  CLEAR_STORE (state) {
    state.products = []
    state.isFirstLoad = true
  },

  SET_PRODUCT_TO_ADD (state, wish) {
    state.productToAdd = wish
  }
}
