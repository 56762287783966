import DataStructure from './DataStructure'
import CreditStatus from './CreditStatus'
export default class Credit extends DataStructure {
  constructor ({
    id = null,
    status = null,
    reason = null,
    comment = null,
    amount = null,
    limit = null,
    valid = null
  }) {
    super()
    this.id = this.isset(id) ? this.convertToNumber(id) : null
    this.status = this.isset(status) ? this.getCreditStatus(status) : null
    this.reason = this.isset(reason) ? this.convertToString(reason) : null
    this.comment = this.isset(comment) ? this.convertToString(comment) : null
    this.amount = this.isset(amount) ? this.convertToNumber(amount) : null
    this.limit = this.isset(limit) ? this.convertToString(limit) : null
    this.valid = this.isset(valid) ? this.convertToBoolean(valid) : null
  }

  getCreditStatus (status) {
    if (!status) {
      return null
    }
    const creditStatus = new CreditStatus(status)
    return creditStatus.getApiObject()
  }
}
