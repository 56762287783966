import {
  sendContactMessage as sendContactMessageApi,
  getContactReasons as getContactReasonsApi
} from '@/api/contact'
import MailContactRequest from '@/models/MailContactRequest'

export const sendContactMessage = ({
  contactMessage,
  headers,
  instance
} = {}) => {
  const mailContactRequest = new MailContactRequest(contactMessage)

  return sendContactMessageApi({
    contactMessage: mailContactRequest.getApiObject(),
    headers,
    instance
  })
}

export const getContactReasons = ({
  headers,
  instance
} = {}) => {
  return getContactReasonsApi({
    headers,
    instance
  })
}
