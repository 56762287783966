import Auth from './auth'

import './middleware'

// Active schemes
import scheme_04e46c82 from './schemes/customLocalStrategy.js'
import scheme_3b2ec9d6 from './schemes/customFacebookStrategy.js'
import scheme_2f844d33 from './schemes/customAppleStrategy.js'
import scheme_011548fc from './schemes/customGoogleStrategy.js'

export default function (ctx, inject) {
  // Options
  const options = {"resetOnError":false,"scopeKey":"scope","rewriteRedirects":false,"fullPathRedirect":false,"watchLoggedIn":true,"redirect":{"login":"/login","logout":"/","home":false,"callback":"/login"},"vuex":{"namespace":"auth"},"cookie":{"prefix":"auth.","options":{"path":"/"}},"localStorage":{"prefix":"auth."},"token":{"prefix":"_token."},"refresh_token":{"prefix":"_refresh_token."},"defaultStrategy":"customLocal"}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // customLocal
  $auth.registerStrategy('customLocal', new scheme_04e46c82($auth, {"_name":"customLocal"}))

  // customFacebook
  $auth.registerStrategy('customFacebook', new scheme_3b2ec9d6($auth, {"_name":"customFacebook"}))

  // customApple
  $auth.registerStrategy('customApple', new scheme_2f844d33($auth, {"_name":"customApple"}))

  // customGoogle
  $auth.registerStrategy('customGoogle', new scheme_011548fc($auth, {"_name":"customGoogle"}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      console.error('[ERROR] [AUTH]', error)
    }
  })
}
