import { CURRENCY } from '@/config'
import { PAGE_TYPE_MAPPING } from '@/config/google/config'
import apiClient from '@/services/apiClient'

export async function getGtmUserData (user, addresses) {
  const userDataObject = await generateUserDataObject(user)
  const userAddressObject = await generateAddressObject(addresses)

  return {
    userDataObject,
    userAddressObject
  }
}

export async function generateAddressObject (addresses) {
  if (!addresses.length) {
    return {}
  }

  const defaultAddresse = addresses.find(item => item.default)

  const hashPhone = defaultAddresse.phoneNumber
    ? await apiClient.post('/generate-encrypted-data', {
      data: defaultAddresse.phoneNumber
    })
    : ''

  return {
    city: defaultAddresse?.city || '',
    zipcode: defaultAddresse?.zipCode || '',
    country: defaultAddresse?.countryName || '',
    phone: hashPhone.data.hashedData
  }
}

export async function generateUserDataObject (userData) {
  const [hashEmail, hashFirstName, hashLastName] = await Promise.all([
    apiClient.post('/generate-encrypted-data', {
      data: userData.email
    }),
    apiClient.post('/generate-encrypted-data', {
      data: userData.firstName
    }),
    apiClient.post('/generate-encrypted-data', {
      data: userData.lastName
    })
  ])

  return {
    id: userData.id,
    email: hashEmail.data.hashedData,
    gender: userData.civility,
    birthday: userData.birthday,
    first_name: hashFirstName.data.hashedData,
    last_name: hashLastName.data.hashedData,
    logged: true
  }
}
export async function generateUserLoggedObject ({ userData, userAddresse, sessionID }, apiUrl) {
  const addresse = {
    city: null,
    country: null,
    zipcode: null
  }
  if (userAddresse.length) {
    const defaultAddresse = userAddresse.find(addresse => addresse.default)
    addresse.city = defaultAddresse.city
    addresse.country = defaultAddresse.countryIsoCode2
    addresse.zipcode = defaultAddresse.zipcode
  }

  const userAddressObject = await generateUserDataObject(userData)

  return {
    ...userAddressObject,
    ...addresse,
    sessionID
  }
}
export function cleanHierarchyCategory (category) {
  const regex = /~~[0-9]*~~/
  const split = Object.values(category).map((cat) => {
    return cat.split(regex).slice(-1).join('')
  })
  return {
    category: split[0] || null,
    subcategory: split[0] === split[1] ? null : split[1] || null
  }
}
export function getCategoryObject (category) {
  return {
    category: category.relative?.name || category.name,
    subcategory: category.relative?.name ? category.name : null
  }
}
/**
 * Générer un objet produit pour le tracking gtm
 * Methode utiliser dans les listing, sur la home page
 * Pour l'envoie des données à GTM
 *
 * @param {Object} data - Object avec un produit, listingType, position et la langue.
 * @param {Object} data.product - Le product.
 * @param {Number} data.position - La position du produit dans le listing.
 * @param {String} data.locale - La langue à utiliser pour récupérer les datas dans l'objet produit.
 * @returns {Object}
 */
export function generateItemObject ({ product, listingType, position, locale }) {
  const productCategory = cleanHierarchyCategory(product.category.hierarchy[locale])
  return {
    product_name: product.names[locale],
    product_brand: product.displayInformation.title,
    product_id: product.productID,
    product_list_category: productCategory.category,
    product_list_subcategory: productCategory.subcategory,
    product_price: product.prices[locale]?.originalPrice || product.prices.default.originalPrice,
    product_list_name: listingType,
    product_position: position,
    discount: product.promotion.percent
  }
}
function generateDiscountPrice (product, locale, isAlgoliaData) {
  const infoObject = !!product?.info
  const originalePrice = isAlgoliaData ? product.prices[locale]?.originalPrice || product.prices.default.originalPrice : infoObject ? product.info.originPrice : product.originPrice
  const price = isAlgoliaData ? product.prices[locale]?.price || product.prices.default.price : infoObject ? product.info.price : product.price

  return originalePrice === price ? 0 : Math.round((originalePrice - price) * 100) / 100
}
export function generateItemObjectV2 ({
  product,
  isAlgoliaData = false,
  quantity = null,
  locale,
  needDataSizesAttribute = true,
  sizeIdSelected = null
}) {
  // needAttribute
  // Permet d'injecter dans l'objet des datas en plus ou en moins en fonction du besoin
  const isInfoObject = !!product?.info
  const price = isAlgoliaData
    ? product.prices[locale]?.price || product.prices.default.price
    : isInfoObject ? product.info.price : product.price

  const discount = generateDiscountPrice(product, locale, isAlgoliaData)
  const brand = isAlgoliaData ? product.brand?.name || null : isInfoObject ? product.info.brand?.name || null : product.brand?.name || null
  const range = isAlgoliaData ? getCharacteristics('range', product.characteristics[locale]) : isInfoObject ? product.info?.range || null : product?.range || null
  const productManufactured = isInfoObject ? product.info?.manufactured : product?.manufactured
  const sizes = isAlgoliaData ? product?.sizes || [] : isInfoObject ? product.info?.productSizes || [] : product?.productSizes || []
  const productCategory = isAlgoliaData
    ? cleanHierarchyCategory(product.category.hierarchy[locale])
    : isInfoObject ? getCategoryObject(product.info.category) : getCategoryObject(product.category)
  const dataToReturn = {
    product_id: isAlgoliaData ? product.productID.toString() : isInfoObject ? product.info.id.toString() : product.id.toString(),
    name: isAlgoliaData ? product.names[locale] : isInfoObject ? product.info?.name || null : product.name,
    brand_range: range ? `${brand}|${range}` : brand,
    range: product?.label || null,
    artist: getItemArtists(isInfoObject ? product.info.artists : product.artists),
    category: productCategory ? productCategory.category : null,
    subcategory: productCategory ? productCategory.subcategory : null,
    variant: isAlgoliaData ? getCharacteristics('color', product.characteristics[locale], '~~') : isInfoObject ? product.info?.color || null : product?.color || null,
    unit_price: price,
    currency: CURRENCY[locale],
    discount,
    fab: productManufactured !== undefined ? productManufactured : null,
    label: isInfoObject ? getLabels(product.info.marketLabels) : getLabels(product.marketLabels),
    promo: !!discount,
    visible: product.quantity === undefined ? true : isInfoObject ? product.info.quantity > 0 : product.quantity > 0,
    numb_pictures: isInfoObject ? product.info.pictures.length : product.pictures.length
  }
  if (quantity) {
    dataToReturn.quantity = quantity
  }
  if (sizeIdSelected !== null) {
    dataToReturn.size_id = sizeIdSelected ? sizeIdSelected.toString() : null
  }
  if (needDataSizesAttribute) {
    dataToReturn.sizes_ids = generateAttributesIds(sizes)
    dataToReturn.sizes = generateAvailableSizes(sizes)
  }
  return dataToReturn
}

export function generateAvailableSizes (sizes) {
  if (!sizes.length) {
    return null
  }
  const algoliaData = !!sizes[0]?.rank
  return sizes.map(item => algoliaData ? item.size : item.value).join('|')
}
export function generateAttributesIds (sizes) {
  if (!sizes.length) {
    return null
  }
  const algoliaData = !!sizes[0]?.rank
  return sizes.map(item => algoliaData ? item.productSizeId : item.id).join('|')
}
export function generateRefundProduct ({ product }) {
  return {
    quantity: product.quantity,
    product_subcategory: product.info.category.relative?.name ? product.info.category.name : null,
    category: product.info.category.relative?.name || product.info.category.name,
    product_id: product.info.id.toString(),
    name: product.info?.name || '',
    product_brand: product.info.displayInformation.title,
    price: product.info.price,
    product_size_id: product.size?.id.toString() || null,
    discount: product.info.discountPercent
  }
}
export function generateOrderProduct ({ product }) {
  return {
    quantity: product.quantity,
    product_subcategory: product.info.category.relative?.name ? product.info.category.name : null,
    category: product.info.category.relative?.name || product.info.category.name,
    product_id: product.info.id.toString(),
    name: product.info.name,
    product_brand: product.info.displayInformation.title,
    price: product.info.price,
    product_size_id: product.size?.id.toString() || null,
    product_attribute_id: product.size?.id.toString() || null,
    purchasedSize: product.size?.value || null,
    discount: product.info.discountPercent
  }
}

export function createTrackPromotionEvent (eventName, promotionDataObject) {
  return {
    event: eventName,
    page_data: {
      creative_name: promotionDataObject.name,
      creative_slot: promotionDataObject.slot,
      creative_version: promotionDataObject.version
    }
  }
}

export const cleanGtmDataLayer = {
  event: 'clean_dataLayer',
  item_size: undefined,
  item: undefined,
  page_data: undefined,
  customer: undefined,
  product_detail: undefined
}

// Maybe theFunctionDoesTooMuchThingsDueToTheFuckingLongNameLol
// TODO: refactor
export function generatePageTypeBasedOnRouteNameOrListingType (listingType, routeName) {
  if (listingType) {
    return PAGE_TYPE_MAPPING[listingType] ? `${PAGE_TYPE_MAPPING.all}|${PAGE_TYPE_MAPPING[listingType]}` : null
  } else {
    const name = routeName.split('___')[0]
    if (!name.includes('-')) {
      return PAGE_TYPE_MAPPING[name] || null
    } else if (name.startsWith('checkout-basket')) {
      return PAGE_TYPE_MAPPING['checkout-basket']
    } else {
      return PAGE_TYPE_MAPPING[name.split('-')[0]] || null
    }
  }
}

export function generatePageCSBA (pageInfo, listingType, product = null) {
  if (!listingType && !product) {
    return {
      c: null,
      s: null,
      b: null,
      a: null
    }
  }
  let category = null
  let subcategory = null
  let brand = null
  let artiste = null

  if (product) {
    // Si on est sur une page produit
    category = product.category.relative ? product.category.relative.name : product.category.name
    subcategory = product.category.relative ? product.category.name : null
    brand = product.brand ? product.brand.name : null
    artiste = product.artists ? getItemArtists(product.artists) : null
  } else if (listingType && pageInfo && pageInfo.breadcrumb && pageInfo.breadcrumb.parts.length) {
    const categoryObject = generateCatSubCatFromBreadCrumb(pageInfo.breadcrumb.parts, listingType)
    const brandOrArtiste = pageInfo.seo.h1Sections[pageInfo.seo.h1Sections.length - 1]

    category = categoryObject.category
    subcategory = categoryObject.subcategory

    brand = listingType === 'LISTING_BRAND' ? brandOrArtiste : null
    artiste = listingType === 'LISTING_ARTIST' ? brandOrArtiste : null
  }
  return {
    c: category,
    s: subcategory,
    b: brand,
    a: artiste
  }
}
export function generateCatSubCatFromBreadCrumb (breadcrumb, listingType) {
  let cat = null
  let subcat = null
  if (listingType === 'LISTING_CATEGORY') {
    cat = breadcrumb[1]?.title || null
    subcat = breadcrumb[2]?.title || null
  } else if (listingType === 'LISTING_ARTIST') {
    cat = breadcrumb[3]?.title || null
    subcat = breadcrumb[4]?.title || null
  } else {
    cat = breadcrumb[2]?.title || null
    subcat = breadcrumb[3]?.title || null
  }
  return {
    category: cat,
    subcategory: subcat
  }
}
export function generateShippingModeDisplay (shippingList) {
  const relais = []
  const domicile = []
  if (shippingList && shippingList.length) {
    shippingList.forEach((item) => {
      if (item.type === 'point_relais') {
        relais.push(item)
      } else {
        domicile.push(item)
      }
    })
  }
  return {
    shipping_relay: relais.length ? relais.map(item => `${item.origin}-${item.id}`).join('|') : null,
    shipping_home: domicile.length ? domicile.map(item => `${item.origin}-${item.id}`).join('|') : null
  }
}
export function generatePageTitleForGtmTracking (seo) {
  return seo.h1 || seo.title || null
}

export function cleanCharacteristicValue (separator, value) {
  return value.split(separator)[0]
}

export function getLabels (labels) {
  const isArray = (!!labels) && (labels.constructor === Array)
  if ((isArray && !labels.length) || (!isArray && Object.keys(labels).length === 0)) {
    return null
  }
  if (isArray) {
    return labels.map(item => item.type).join('|')
  } else {
    return Object.keys(labels).join('|')
  }
}

export function getCharacteristics (
  characteristicsName,
  characteristicsCorrespondingToLocale,
  separator = null
) {
  // https://dev.to/aman_singh/what-s-the-deal-with-object-prototype-hasownproperty-call-4mbj
  if (!characteristicsCorrespondingToLocale || !Object.prototype.hasOwnProperty.call(characteristicsCorrespondingToLocale, characteristicsName)) {
    return null
  }
  return characteristicsCorrespondingToLocale[characteristicsName].map((item) => {
    return separator ? cleanCharacteristicValue(separator, item) : item
  }).join('|')
}

export function getItemArtists (artists) {
  if (!artists.length) {
    return null
  }
  return artists.map(item => item.name).join('|')
}
