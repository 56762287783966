import { api } from './instance'

api.interceptors.request.use(
  config => config,
  error => Promise.reject(error)
)

export * from './instance'
export * from './config'
export * from './page'
export * from './product'
export * from './template'
export * from './translation'
export * from './website'
export * from './account'
export * from './checkout'
export * from './cms'
export * from './contact'
export * from './outlet'
export * from './referer'
export * from './sizeGuide'
export * from './summary'
export * from './templatePage'

export * from './secure/giftcard'
export * from './secure/order'
export * from './secure/creditCardToken'
export * from './secure/checkout'
export * from './secure/wish'
export * from './secure/account'
export * from './secure/product'
export * from './secure/shipping'
