import {
  getProduct as getApiProduct,
  getProductInformations as getProductInformationsApi,
  getProductAssociated as getProductAssociatedApi,
  getScores as getScoresApi,
  createProductAlert as createProductAlertApi,
  getProductAlertList as getProductAlertListApi,
  deleteProductAlert as deleteProductAlertApi
} from '../api'
import ProductAlert from '@/models/ProductAlert'

export const getProduct = ({ productId, headers, axios } = {}) => getApiProduct({ productId, headers, instance: axios })
export const getProductInformations = ({ productId, headers, axios } = {}) => getProductInformationsApi({ productId, headers, instance: axios })
export const getProductAssociated = ({ productId, headers, axios } = {}) => getProductAssociatedApi({ productId, headers, instance: axios })

export const getScores = ({
  headers,
  instance
} = {}) => {
  return getScoresApi({
    headers,
    instance
  })
}

export const createProductAlert = ({
  productAlert,
  headers,
  instance
} = {}) => {
  const productAlertObject = new ProductAlert(productAlert)

  return createProductAlertApi({
    productAlert: productAlertObject.getApiObject(),
    headers,
    instance
  })
}

export const deleteProductAlert = ({
  alertId,
  headers,
  instance
} = {}) => {
  return deleteProductAlertApi({
    alertId,
    headers,
    instance
  })
}

export const getProductAlertList = ({
  headers,
  instance
} = {}) => {
  return getProductAlertListApi({
    headers,
    instance
  })
}
