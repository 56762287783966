import { api } from '../instance'
export const getShippings = (options = {}) => {
  const {
    productIds,
    price,
    weight,
    countryCode,
    promotionCode,
    headers,
    instance
  } = options
  return (instance || api).get(
    '/secure/shippings',
    {
      params: {
        productIds,
        price,
        weight,
        countryCode,
        promotionCode
      },
      headers
    }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getShippingRelayPoints = (options = {}) => {
  const {
    productIds,
    countryCode,
    weight,
    zipCode,
    city,
    address,
    origin,
    id,
    headers,
    instance
  } = options

  if (!origin || !id) {
    throw new TypeError('origin or id parameters cannot be undefined or null')
  }

  return (instance || api).get(
    `/secure/shippings/${origin}s/${id}/relay-points`,
    {
      params: {
        productIds,
        countryCode,
        weight,
        zipCode,
        city,
        address,
        origin,
        id
      }
    },
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}
