import {
  createAccount as createAccountApi,
  resetPassword as resetPasswordApi
} from '../api'

export const createAccount = ({
  customerObject,
  headers,
  instance
} = {}) => {
  return createAccountApi({ customerObject, headers, instance })
}

export const resetPassword = ({
  email,
  headers,
  instance
} = {}) => {
  return resetPasswordApi({ email, headers, instance })
}
