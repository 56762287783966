import { sendSentryMessage } from '@/helpers/errorHelper'
import { getAccountProfile, getAccountCustomerAddresses } from '@/services/legacy/account'
import { refresh } from '@/services/legacy/authentication'
import ApiFront from '@/services/legacy/agent'

export default class BaseCustomStrategy {
  constructor (auth, options) {
    this.$auth = auth
    this.name = options._name
  }

  _getBearer () {
    const defaultClient = ApiFront.ApiClient.instance
    return defaultClient.authentications.bearer
  }

  _setToken (token) {
    if (token) {
      const bearer = this._getBearer()
      bearer.apiKey = 'bearer ' + token
    }
  }

  _clearToken () {
    const defaultClient = ApiFront.ApiClient.instance
    const bearer = defaultClient.authentications.bearer
    bearer.apiKey = null
  }

  syncToken () {
    const token = this.$auth.syncToken(this.name)
    this._setToken(token)
  }

  async mounted () {
    this.syncToken()
    await this.$auth.fetchUserOnce()
  }

  async loginFn () {}

  async login () {
    // Ditch any leftover local tokens before attempting to log in
    this._logoutLocally()

    const res = await this.loginFn(...arguments)

    const token = res.token
    const refreshToken = res.refresh_token

    this.$auth.setToken(this.name, token)
    this._setToken(token)

    if (refreshToken && refreshToken.length) {
      this.$auth.setRefreshToken(this.name, 'bearer ' + refreshToken)
    }

    return this.fetchUser()
  }

  async refreshToken (needFetchUser = true) {
    const refreshTokenDefined = this.$auth.getRefreshToken(this.name)
    if (!refreshTokenDefined) {
      return
    }
    try {
      const res = await refresh(refreshTokenDefined.split(' ')[1])
      const token = res.token
      const refreshToken = res.refresh_token
      this.$auth.setToken(this.name, token)
      this._setToken(token)
      if (refreshToken && refreshToken.length) {
        this.$auth.setRefreshToken(this.name, 'bearer ' + refreshToken)
      }
      if (needFetchUser) {
        return this.fetchUser()
      }
    } catch (e) {
      this.logout()
    }
  }

  async fetchUser () {
    // Token is required but not available
    if (!this.$auth.getToken(this.name)) {
      return
    }
    try {
      const user = await getAccountProfile()
      this.$auth.setUser(user)
      await this.fetchUserAddtesses()
    } catch (err) {
      if (err.response.body.code === 401) {
        await this.refreshToken()
      } else {
        sendSentryMessage.bind(this, 'Unable to fetch basic connection info', 'fatal', {
          action: 'getAccountProfile or fetchUserAddresses',
          exception: err
        })()
        throw new Error(err)
      }
    } finally {
      // Deleting the token from the headers only on the instance of API front on server side
      // (won't be needed to access the pages because the calls are only made from the client side)
      if (process.server) {
        const bearer = this._getBearer()
        delete bearer.apiKey
      }
    }
  }

  async fetchUserAddtesses () {
    try {
      const addresses = await getAccountCustomerAddresses()
      this.$auth.$storage.setState('addresses', addresses)
    } catch (err) {
      sendSentryMessage.bind(this, 'Unable to fetch customer addresses', 'fatal', {
        action: 'getAccountCustomerAddresses',
        exception: err
      })()
      throw new Error(err)
    }
  }

  logout () {
    return this._logoutLocally()
  }

  _logoutLocally () {
    this._clearToken()
    this.$auth.$storage.setState('addresses', null)
    return this.$auth.reset()
  }
}
