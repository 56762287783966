import BaseCustomStrategy from '@/auth/BaseCustomStrategy'
import { appleConnect } from '@/services/legacy/socialConnect'

export default class CustomAppleScheme extends BaseCustomStrategy {
  async loginFn () {
    const data = arguments[0] || null
    const redirectURI = arguments[1] || null

    const { authorization, user } = data

    return await appleConnect(authorization?.code, user?.name?.firstName, user?.name?.lastName, redirectURI)
  }
}
