import DataStructure from './DataStructure'
export default class RelayPointSchedule extends DataStructure {
  constructor ({
    day = null,
    openingHours = null
  }) {
    super()
    this.day = this.isset(day) ? this.convertToNumber(day) : null
    this.openingHours = this.isset(openingHours) ? this.convertToString(openingHours) : null
  }
}
