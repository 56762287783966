import { isStaticPage } from '@/helpers/pageHelper'
export default function generateSeo (context) {
  const { globalPageInfo, store, app, route } = context
  /*
  * On test en premier si on est sur une page static ou non
  * Grace au helper isStaticPage
  * Renvoie un objet de ce type :
  * { static:Boolean, type:String }
  * Page static: homePage, Toutes les pages account, Toutes les pages panier
  * Page dynamic: Toutes les autres pages (listing, detail produit, cms)
  *
  * Si on est sur une page static alors on utilise pas le parseUrl
  * On appel pas le getSeo pour recupérer les infos seo.
  *
  * Si on est sur une page dynamique :
  * On appel en premier le parseUrl pour recup le pageInfo de la page
  * On appel getSeo (function generateSeo) puis on stock le retour dans le store
  *
  * Les data concernant le seo vont être utilisés dans la mixins page
  */
  const staticPage = isStaticPage(route)
  if (staticPage.static) {
    store.dispatch('setSeo', {
      title: app.i18n.t(`seo.${staticPage.type}.title`),
      description: app.i18n.t(`seo.${staticPage.type}.description`),
      h1: null,
      header: null,
      footer: null
    })
  } else if (!staticPage.static && globalPageInfo) {
    store.dispatch('setSeo', globalPageInfo.seo)
  }
}
