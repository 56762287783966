<template>
  <div
    class="c-input"
    :class="{
      'c-input--with-icon': icon,
      'c-input--nok': error,
      'c-input--ok': ok,
      'c-input--small': small,
      'c-input--centerdPlaceHolder': centeredPlaceHolder
    }"
  >
    <label
      v-if="label"
      :for="id"
    >
      {{ label }}
      <template v-if="required && needStar">*</template>
    </label>
    <div :class="{'password': enablePasswordHelper}">
      <input
        :id="id"
        :value="value"
        :type="type"
        :placeholder="placeholder"
        :autocomplete="autocomplete"
        :pattern="pattern"
        :required="required"
        :data-cy="dataCy"
        :maxlength="inputMaxLength"
        @input="input"
        @blur="$emit('blur')"
      >
      <button v-if="enablePasswordHelper && value.length" id="helper-button-password" type="button" :class="passwordButtonHelperClass" @click="$emit('show-password')" />
      <span />
      <div v-if="icon" class="c-input__icon">
        <slot name="icon" />
      </div>
    </div>
    <div v-if="!!$slots.info" class="c-input__info">
      <slot name="info" />
    </div>
  </div>
</template>

<script>
/**
 * The `Input` component : simple input component with extra features
 **/
export default {
  props: {
    /**
     * input id (used to make link with the label)
     */
    id: {
      type: String,
      default: 'default'
    },
    /**
     * the input value
     */
    value: {
      type: String,
      default: ''
    },
    inputMaxLength: {
      type: String,
      default: ''
    },
    /**
     * if true, the icon slot is displayed
     */
    icon: {
      type: Boolean,
      default: false
    },
    /**
     * The text displayed in the input if the user hasn't type anything
     */
    placeholder: {
      type: String,
      default: null
    },
    centeredPlaceHolder: {
      type: Boolean
    },
    /**
     * The input type. Can be number, text, date, etc...(all the valid input type)
     */
    type: {
      type: String,
      default: 'text'
    },
    label: {
      type: String,
      default: null
    },
    pattern: {
      type: String,
      default: null
    },
    error: {
      type: Boolean,
      default: false
    },
    ok: {
      type: Boolean,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    autocomplete: {
      type: String,
      default: 'off'
    },
    small: {
      type: Boolean,
      default: false
    },
    /* cypress integration */
    dataCy: {
      type: String,
      default: null
    },
    needStar: {
      type: Boolean,
      default: true
    },
    enablePasswordHelper: Boolean
  },

  computed: {
    passwordButtonHelperClass () {
      return this.type === 'password' ? 'password__button--show' : 'password__button--hide'
    }
  },

  methods: {
    input (event) {
      this.$emit('input', event.target.value)
    }
  }
}
</script>

<style lang="scss" scoped>
.c-input {
  $c: &;

  label {
    padding: 0;
    padding-left: 0.9rem;
    margin: 0;
    margin-bottom: 0.6rem;
    color: $grey-600;
    font-size: $font-size-sm;
    font-weight: 500;
    line-height: 1;
  }

  div {
    position: relative;

    .password {
      &__button--show,
      &__button--hide {
        position: absolute;
        top: 0;
        right: 1rem;
        bottom: 0;
        display: flex;
        align-items: center;

        &::after {
          position: relative;
          z-index: 1;
          display: block;
          width: 3rem;
          height: 3rem;
          background-image: url('~/assets/images/icn_visible.png');
          background-repeat: no-repeat;
          background-size: contain;
          content: '';
        }
      }

      &__button--show {
        &::after {
          background-image: url('~/assets/images/icn_invisible.png');
        }
      }
    }

    > span {
      position: absolute;
      top: 50%;
      right: 2rem;
      width: 1rem;
      height: 1rem;
      content: "";
      opacity: 0;
      transform: translateY(-50%);
      transition: $default-transition;
    }
  }

  input {
    width: 100%;
    padding: 1.4rem 2.1rem 1.3rem;
    border: 0.2rem solid $grey-1100;
    color: $grey-300;
    font-family: Roboto, sans-serif;
    font-size: $font-size-sm;
    font-weight: 500;
    transition: $default-transition;

    &:focus {
      border-color: $grey-300;
    }
  }

  &--centerdPlaceHolder {
    input {
      &::placeholder {
        text-align: center;
      }
    }
  }

  &--small {
    input {
      padding-top: 0.7rem;
      padding-bottom: 0.7rem;
    }
  }

  &--ok input {
    padding-right: 3.3rem;
    border: 0.2rem solid $grey-1100;

    + span {
      background-image: url("~assets/images/ok.png");
      opacity: 1;
    }
  }

  &--nok input {
    border-color: $primary-dark;

    + span {
      background-image: url("~assets/images/nok.png");
      opacity: 1;
    }
  }

  &__flex {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1.8rem;

    > * {
      width: 50%;
      padding: 0 1.8rem;
    }

    @include mq($until: tablet) {
      margin: 0;

      > * {
        width: 100%;
        padding: 0;

        &:not(:last-child) {
          margin-bottom: 1.5rem;
        }
      }
    }
  }

  &__info {
    width: 34.2rem;
    height: 1.1rem;
    margin-top: 0.5rem;
    color: $grey-800;
    font-size: $font-size-sm;
    font-style: italic;
    font-weight: 400;
    line-height: 1;
  }

  &--with-icon {
    position: relative;

    input {
      padding-right: 3rem;
    }
  }

  &__icon {
    position: absolute !important;
    top: 50%;
    right: 1.6rem;
    display: flex;
    width: 2.5rem;
    height: 2.5rem;
    align-items: center;
    justify-content: center;
    padding-left: 1rem;
    background: $white;
    fill: $grey-300;
    transform: translateY(-50%);

    & ::v-deep svg {
      display: block;
      width: 1.6rem;
    }
  }
}
</style>
