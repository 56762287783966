import { api } from '../instance'
export const createProductScore = (options = {}) => {
  const { productId, productScore, headers, instance } = options
  return (instance || api).post(
    `/secure/products/${productId}/score`,
    { ...productScore },
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}
