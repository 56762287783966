<template>
  <nuxt-link
    v-if="type === 'link' && to"
    :to="localePath(to)"
    :href="to"
    class="c-btn"
    :class="className"
    :title="title"
    :data-cy="dataCy"
  >
    <slot />
    <span
      v-if="arrow"
      class="c-btn__right-arrow"
    >
      <ArrowRightBig />
    </span>
  </nuxt-link>
  <a
    v-else-if="type === 'link' && href"
    :href="href"
    class="c-btn"
    :class="className"
    :title="title"
    :data-cy="dataCy"
    @click.prevent="$emit('click')"
  >
    <slot />
    <span
      v-if="arrow"
      class="c-btn__right-arrow"
    >
      <ArrowRightBig />
    </span>
  </a>
  <button
    v-else
    class="c-btn"
    :type="type"
    :class="className"
    :disabled="disabled"
    :title="title"
    :data-cy="dataCy"
    @click="$emit('click', $event)"
    @mouseenter="showTooltip = true"
    @focusin="showTooltip = true"
    @mouseleave="showTooltip = false"
    @focusout="showTooltip = false"
  >
    <slot />
    <transition name="fade-up">
      <span
        v-if="showTooltip && tooltip"
        class="c-btn__tooltip"
      >
        {{ tooltip }}
      </span>
    </transition>
    <span
      v-if="arrow"
      class="c-btn__right-arrow"
    >
      <ArrowRightBig />
    </span>
    <Loader
      v-if="loading"
      class="c-btn__loader"
    />
  </button>
</template>

<script>
import ArrowRightBig from '@/components/atoms/icons/ArrowRightBig'
import Loader from '@/components/atoms/global/loader/Loader'
/**
 * The `Button` component: nuxt-link or button, styled to match the LBO design
 */
export default {
  components: {
    ArrowRightBig,
    Loader
  },
  props: {
    /**
     * type of the button : possible value : `button` or `link`
     */
    type: {
      type: String,
      default: 'button'
    },
    /**
     * if type is link, the destination of the link (in case of nuxt-link)
     */
    to: {
      type: String,
      default: null
    },
    /**
     * if type is link, the destination of the link (in case of external or normal link)
     */
    href: {
      type: String,
      default: null
    },
    /**
     * set the display to primary
     */
    primary: {
      type: Boolean,
      default: false
    },

    /**
     * set the display to accent
     */
    accent: {
      type: Boolean,
      default: false
    },

    /**
     * activate the grey mode : background is grey
     */
    grey: {
      type: Boolean,
      default: false
    },

    /**
     * activate the black mode : background is black
     */
    black: {
      type: Boolean,
      default: false
    },

    /**
     * if type is button, disabled the button
     */
    disabled: {
      type: Boolean,
      default: false
    },

    /**
     * switch color between background and border / text
     */
    reverse: {
      type: Boolean,
      default: false
    },

    /**
     * the title display on mouseover
     */
    title: {
      type: String,
      default: null
    },

    /**
     * display a arrow after the text in the button
     */
    arrow: {
      type: Boolean,
      default: false
    },

    /**
     * change the hover effect
     */
    redHover: {
      type: Boolean,
      default: false
    },

    /**
     * set the button full content width
     */
    large: {
      type: Boolean,
      default: false
    },

    /**
     * reduce padding
     */
    small: {
      type: Boolean,
      default: false
    },

    /**
     * add a loader under the text
     */
    loading: {
      type: Boolean,
      default: false
    },

    /**
     * the content of the tooltip to be displayed on mouseover
     */
    tooltip: {
      type: String,
      default: null
    },

    /**
     * Style the bouton as disabled, but allow mouseenter and mouseleave action
     */
    fakeDisabled: {
      type: Boolean,
      default: false
    },
    /**
     * Display button as link
     */
    link: {
      type: Boolean,
      default: false
    },

    /**
     * if button contains only icon
     */
    icon: {
      type: Boolean,
      default: false
    },

    /**
     * if button should be displayed with facebook style
     */
    facebookConnect: {
      type: Boolean,
      default: false
    },

    /**
     * if button should be displayed with facebook style
     */
    appleConnect: {
      type: Boolean,
      default: false
    },
    googleConnect: {
      type: Boolean,
      default: false
    },
    /**
     * if button should be large on mobile only
     */
    largeMobile: {
      type: Boolean,
      default: false
    },

    /**
     * data attribute for cypress test
     */
    dataCy: {
      type: String,
      default: null
    }
  },

  data () {
    return {
      showTooltip: false
    }
  },

  computed: {
    className () {
      return {
        'c-btn--primary': this.primary,
        'c-btn--accent': this.accent,
        'c-btn--redHover': this.redHover,
        'c-btn--grey': this.grey,
        'c-btn--disabled': this.disabled,
        'c-btn--reverse': this.reverse,
        'c-btn--red-hover': this.redHover,
        'c-btn--large': this.large,
        'c-btn--small': this.small,
        'c-btn--arrow': this.arrow,
        'c-btn--fake-disabled': this.fakeDisabled,
        'c-btn--link': this.link,
        'c-btn--black': this.black,
        'c-btn--icon': this.icon,
        'c-btn--connect': this.appleConnect || this.facebookConnect || this.googleConnect,
        'c-btn--apple': this.appleConnect,
        'c-btn--fb': this.facebookConnect,
        'c-btn--google': this.googleConnect,
        'c-btn--large-mobile': this.largeMobile
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.c-btn {
  $c: &;

  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 2.7rem 6rem;
  border: 0.2rem solid $grey-100;
  margin: 0;
  background: transparent;
  border-radius: 0;
  color: $grey-300;
  cursor: pointer;
  font-size: $font-size-sm;
  letter-spacing: 0.1rem;
  line-height: 1;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: $transition;
  vertical-align: middle;

  &:disabled,
  &#{$c}--disabled {
    border-color: $grey-600 !important;
    background: $grey-1300;
    color: $grey-800;
    cursor: auto;
    pointer-events: none;
  }

  &--redHover {
    &:hover,
    &:focus {
      border-color: $primary !important;
      color: $primary;
    }
  }

  @include mq($until: tablet) {
    padding: 1.8rem 3.8rem;
    border-width: 0.1rem;
  }

  svg {
    fill: currentcolor;
  }

  &:focus,
  &:hover {
    border-color: $grey-200;
  }

  &:focus-visible {
    border-color: $black;
  }

  &--reverse {
    border-color: $white;
    color: $white;

    &:focus,
    &:hover {
      background: $white;
      color: $primary;
    }

    &:disabled {
      border-color: $white !important;
      background: transparent;
      color: $white;
    }
  }

  &--no-border {
    padding: 1.3rem;
    border: none;
    white-space: nowrap;
  }

  &--primary {
    border: none;
    background-color: $primary-dark;
    color: $white;

    &:focus,
    &:hover {
      background-color: darken($primary-dark, 5%);
      color: $white;
    }

    &:disabled {
      background-color: $primary-dark;
      color: $white;
    }
  }

  &--icon {
    padding: 2rem;
  }

  &--black {
    padding: 1.6rem 2rem;
    border: 0.2rem solid $grey-200;
    background: $grey-200;
    color: $white;
    font-size: $font-size-sm;
    font-weight: 300;
  }

  &--accent {
    border-color: $accent;
    background-color: $accent;
    color: $white;

    &:focus,
    &:hover {
      border-color: darken($accent, 5%);
      background-color: darken($accent, 5%);
    }

    &:disabled {
      border-color: $accent !important;
      background-color: $accent;
      color: $white;
    }
  }

  &--fake-disabled {
    cursor: initial;
  }

  &--grey {
    border-color: $grey-1300;
    background-color: $grey-1300;
    color: $grey-300;

    &:disabled {
      border: none;
      border-color: transparent;
      background-color: $grey-1300;
      color: $white;
      cursor: auto;
    }

    &:hover {
      border-color: transparent;
    }
  }

  &--link {
    padding: 0;
    border: none;
    margin: 0;
    color: $grey-600;
    font-size: $font-size-sm;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 1;
    text-decoration: underline;
    text-transform: none;
  }

  &--large {
    width: 100%;
  }

  &--small {
    padding: 1.5rem;
  }

  &--large-mobile {
    @include mq($until: tablet) {
      width: 100%;
    }
  }

  &--with-arrow {
    padding-right: 3rem;
  }

  // Style pour le c-login-btn
  &--connect {
    display: flex;
    min-width: 30.5rem;
    align-items: center;
    justify-content: flex-start;
    padding: 2.3rem 3.2rem;
    border: none;
    margin: 0 auto;
    border-radius: 3.5rem;
    font-size: $font-size-md;
    letter-spacing: 0.05rem;
    text-transform: none;

    @include mq($until: tablet) {
      min-width: 100%;

      span {
        flex-grow: 1;
        text-align: center;
      }
    }
  }

  &--fb {
    background-color: $facebook;
    color: $white;

    svg {
      display: block;
      width: 1.2rem;
      height: 2.3rem;
      margin-right: 3rem;
      fill: currentcolor;
    }

    @include mq($until: tablet) {
      svg {
        margin-right: 3.6rem;
      }
    }
  }

  &--google {
    margin-top: 4.8rem;
    background-color: $google;
    color: $grey-450;

    svg {
      display: block;
      width: 2rem;
      height: 2rem;
      margin-right: 2.2rem;
    }
  }

  &--apple {
    margin-top: 4.8rem;
    background-color: $black;
    color: $white;

    svg {
      display: block;
      width: 2rem;
      height: 2rem;
      margin-right: 2.2rem;
      margin-bottom: 0.2rem;
      fill: currentcolor;
    }

    @include mq($until: tablet) {
      svg {
        margin-right: 2.8rem;
      }
    }
  }

  &__right-arrow {
    &::v-deep svg {
      display: block;
      width: 1.1rem;
      height: 1.1rem;
      margin-left: 1rem;
      fill: currentcolor;
    }
  }

  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 2rem;
    margin-top: 0.5rem;
    color: currentcolor;
    transform: translateX(-50%);

    & ::v-deep svg {
      width: 100%;
      fill: currentcolor;
    }
  }

  &__tooltip {
    position: absolute;
    z-index: 10;
    right: 0;
    bottom: calc(100% + 2rem);
    left: 0;
    padding: 1rem 2rem;
    background-color: $grey-300;
    letter-spacing: 0;
    line-height: 18.2px;
    pointer-events: none;
    text-transform: none;
    transition: all 0.3s ease-in;

    &::after {
      position: absolute;
      top: 100%;
      left: 50%;
      width: 0;
      height: 0;
      border-width: 10px 7.5px 0;
      border-style: solid;
      border-color: $grey-300 transparent transparent transparent;
      content: "";
      transform: translateX(-50%);
    }
  }
}
</style>
