export function buildCollectionParam (param, collectionFormat) {
  if (param == null) {
    return null
  }
  switch (collectionFormat) {
    case 'csv':
      return param.join(',')
    case 'ssv':
      return param.join(' ')
    case 'tsv':
      return param.join('\t')
    case 'pipes':
      return param.join('|')
    case 'multi':
      return param
    case 'passthrough':
      return param
    default:
      throw new Error('Unknown collection format: ' + collectionFormat)
  }
}
