import ApiFront from '@/services/legacy/agent'

const api = new ApiFront.AccountApi()
// TODO: Encore utiliser dans le baseCustomStrategy
export function getAccountProfile () {
  return new Promise((resolve, reject) => {
    api.getAccountProfileV2((error, _, response) => {
      if (error) {
        reject(error)
      } else {
        resolve(response.body)
      }
    })
  })
}
// TODO: Encore utiliser dans le baseCustomStrategy
export function getAccountCustomerAddresses () {
  return new Promise((resolve, reject) => {
    api.getAccountCustomerAddresses((error, _, response) => {
      if (error) {
        reject(error)
      } else {
        resolve(response.body)
      }
    })
  })
}
