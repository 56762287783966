import DataStructure from './DataStructure'

export default class Voucher extends DataStructure {
  constructor ({
    id = null,
    status = null,
    name = null,
    amount = null,
    limit = null,
    valid = null
  }) {
    super()
    this.id = this.isset(id) ? this.convertToNumber(id) : null
    this.status = this.isset(status) ? this.convertToNumber(status) : null
    this.name = this.isset(name) ? this.convertToString(name) : null
    this.amount = this.isset(amount) ? this.convertToNumber(amount) : null
    this.limit = this.isset(limit) ? this.convertToString(limit) : null
    this.valid = this.isset(valid) ? this.convertToBoolean(valid) : null
  }
}
