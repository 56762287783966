export const ERROR_CODES = {
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER: 500
}

export const GEOLOC_API_ERROR_CODES = {
  PERMISSION_DENIED: 1,
  POSITION_UNAVAILABLE: 2,
  TIMEOUT: 3
}
