import { API_URL, URL_PROTOCOL } from '@/config'
const ApiFront = require('api_front')

export default function (context) {
  const { $config } = context
  // Set domain to international store
  if (process.server) {
    context.store.dispatch('international/setInterDomain', `${URL_PROTOCOL}://${context.req.headers.host}`)
  }
  // Defined Default client at top
  const defaultClient = ApiFront.ApiClient.instance

  // We define the basePath
  defaultClient.basePath = API_URL

  // Default timeout
  defaultClient.timeout = 3 * 60 * 1000 // 3 minutes

  // Settings first defaultHeaders like device, Content-type user-agent...
  defaultClient.defaultHeaders = {
    'Lbo-Device': (context.$ua.deviceType() === 'pc' || context.$ua.isFromTablet()) ? 0 : 1,
    'Lbo-Store': $config.store,
    'Accept-Language': $config.locale,
    'Content-Type': 'application/json'
  }

  // Adds the user agent for requests if the rendering mode is SRR
  // Adds no cache for first call if mode SSR
  if (process.server) {
    Object.assign(
      defaultClient.defaultHeaders,
      { 'User-Agent': context.$ua._ua }
    )
    if (context.route.query?.no_cache === '1') {
      Object.assign(
        defaultClient.defaultHeaders,
        { 'Cache-Control': 'no-cache' }
      )
    }
  }
}
