import {
  createProductScore as createProductScoreApi
} from '../../api'
import ProductScore from '@/models/ProductScore'

export const createProductScore = ({
  productId,
  productScore,
  headers,
  instance
} = {}) => {
  const productScoreObject = new ProductScore(productScore)

  return createProductScoreApi({
    productId,
    productScore: productScoreObject.getApiObject(),
    headers,
    instance
  })
}
