import { api } from '../instance'

export const getCards = (options = {}) => {
  const { headers, instance } = options
  return (instance || api).get(
    '/secure/cards',
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const createCard = (options = {}) => {
  const { paymentDataObject, headers, instance } = options
  return (instance || api).post(
    '/secure/cards',
    { ...paymentDataObject },
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const deleteCard = (options = {}) => {
  const { cardId, headers, instance } = options
  return (instance || api).delete(
    `/secure/cards/${cardId}`,
    {},
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}
