import { CURRENCY } from '@/config'
import { sendSentryMessage } from '@/helpers/errorHelper'
export const state = () => {
  return {
    locale: 'fr',
    defaultClientData: {
      logged: false,
      id: null,
      email: null,
      gender: null,
      birthday: null,
      sessionID: null,
      phone: null,
      first_name: null,
      last_name: null
    },
    defaultClientAddresse: {
      city: null,
      zipcode: null,
      country: null
    },
    page_data: {
      type: null,
      title: null,
      url: null,
      category: null,
      subcategory: null,
      brand: null,
      artist: null,
      country: 'fr',
      language: 'fr',
      iso_code: 'fr',
      store: 'laboutiqueofficielle'
    },
    sessionIDUpdate: false,
    isoCodeSentToGTM: false,
    readyToTrack: false,
    productData: null,
    firstPageView: true
  }
}
// getters
export const getters = {
  getPageDataType (state) {
    return state.page_data.type
  },
  getProductData (state) {
    return state.productData
  },
  getDefaultClientData (state) {
    return state.defaultClientData
  },
  getDefaultClientAddresse (state) {
    return state.defaultClientAddresse
  },
  getCombinedClientData (state) {
    return {
      ...state.defaultClientData,
      ...state.defaultClientAddresse
    }
  }
}
export const actions = {
  setFirstPageView ({ commit }, payload) {
    commit('SET_FIRST_PAGE_VIEW', payload)
  },
  setProductData ({ commit }, payload) {
    commit('SET_PRODUCT_DATA', payload)
  },
  setUUID ({ commit }, payload) {
    commit('SET_UUID', payload)
  },
  setPageUrl ({ commit }, payload) {
    commit('SET_PAGE_URL', payload)
  },
  setPageTitle ({ commit }, payload) {
    commit('SET_PAGE_TITLE', payload)
  },
  setPageCSBA ({ commit }, payload) {
    commit('SET_PAGE_CATEGORY', payload.c)
    commit('SET_PAGE_SUB_CATEGORY', payload.s)
    commit('SET_PAGE_BRAND', payload.b)
    commit('SET_PAGE_ARTISTE', payload.a)
  },
  setPageType ({ commit }, payload) {
    commit('SET_PAGE_TYPE', payload)
  },
  setDefaultClientAddresse ({ commit }, payload) {
    commit('SET_DEFAULT_CLIENT_ADDRESSE', payload)
  },
  setCountryAndLanguage ({ commit }, payload) {
    commit('SET_COUNTRY', payload.countryCode)
    commit('SET_LANGUAGE', payload.langCode)
  },
  setDefaultClientData ({ commit }, payload) {
    commit('SET_DEFAULT_CLIENT_DATA', payload)
  },
  sendLocaleToGTM ({ state, commit }, { gtm, defaultInterStore }) {
    if (gtm && !state.isoCodeSentToGTM) {
      if (process.client) {
        window.addEventListener('CookiebotOnAccept', () => {
          commit('SET_READY_TO_TRACK', true)
        })
        window.addEventListener('CookiebotOnDecline', () => {
          commit('SET_READY_TO_TRACK', true)
        })
      }
      gtm.trackEvent({
        event: 'iso_code',
        page_data: {
          iso_code: defaultInterStore.countryCode,
          default_locale: defaultInterStore.defaultLocale
        }
      })
      commit('SET_ISOCODE_SENT_TO_GTM', true)
    }
  },
  setPageDataStore ({ commit }, payload) {
    commit('SET_PAGE_DATA_STORE', payload)
  },
  setPageDataIsoCode ({ commit }, payload) {
    commit('SET_PAGE_DATA_ISO_CODE', payload)
  },
  sendPageData ({ state }, { gtm }) {
    if (!gtm) {
      return
    }
    try {
      gtm.trackEvent({
        event: 'lbo_init_page_data_object',
        page_data: {
          ...state.page_data,
          currency: CURRENCY[state.locale]
        }
      })
    } catch (e) {
      sendSentryMessage.bind(this.app, 'Unable to send data to GTM', 'error', {
        action: 'sendPageData',
        gtm: gtm.toString(),
        event: 'lbo_init_page_data_object',
        call: 'trackEvent',
        exception: e
      })()
    }
  },
  sendDataToGTM ({ state }, { gtm, eventName }) {
    if (gtm) {
      try {
        gtm.trackEvent({
          event: eventName,
          customer: {
            ...state.defaultClientData,
            ...state.defaultClientAddresse
          },
          page_data: {
            ...state.page_data,
            currency: CURRENCY[state.locale]
          }
        })
      } catch (e) {
        sendSentryMessage.bind(this.app, 'Unable to send data to GTM', 'error', {
          action: 'sendPageData',
          gtm: gtm.toString(),
          event: eventName,
          call: 'trackEvent',
          exception: e
        })()
      }
    }
  },
  resetUserData ({ commit }) {
    commit('RESET_USER_DATA')
  }
}
export const mutations = {
  SET_FIRST_PAGE_VIEW (state, payload) {
    state.firstPageView = payload
  },
  SET_UUID (state, payload) {
    state.defaultClientData.sessionID = payload
    state.sessionIDUpdate = true
  },
  SET_PRODUCT_DATA (state, payload) {
    state.productData = payload
  },
  SET_PAGE_URL (state, payload) {
    state.page_data.url = payload
  },
  SET_PAGE_TITLE (state, payload) {
    state.page_data.title = payload
  },
  SET_PAGE_ARTISTE (state, payload) {
    state.page_data.artist = payload
  },
  SET_PAGE_CATEGORY (state, payload) {
    state.page_data.category = payload
  },
  SET_PAGE_SUB_CATEGORY (state, payload) {
    state.page_data.subcategory = payload
  },
  SET_PAGE_BRAND (state, payload) {
    state.page_data.brand = payload
  },
  SET_PAGE_TYPE (state, payload) {
    state.page_data.type = payload
  },
  SET_READY_TO_TRACK (state, payload) {
    state.readyToTrack = payload
  },
  SET_ISOCODE_SENT_TO_GTM (state, payload) {
    state.isoCodeSentToGTM = payload
  },
  SET_COUNTRY (state, payload) {
    state.page_data.country = payload
  },
  SET_LANGUAGE (state, payload) {
    state.page_data.language = payload
    state.locale = payload
  },
  SET_DEFAULT_CLIENT_ADDRESSE (state, payload) {
    state.defaultClientAddresse.city = payload.city
    state.defaultClientAddresse.zipcode = payload.zipcode
    state.defaultClientAddresse.country = payload.country
    state.defaultClientData.phone = payload.phone
  },
  SET_DEFAULT_CLIENT_DATA (state, payload) {
    state.defaultClientData = { ...state.defaultClientData, ...payload }
  },
  SET_PAGE_DATA_STORE (state, payload) {
    state.page_data.store = payload
  },
  SET_PAGE_DATA_ISO_CODE (state, payload) {
    state.page_data.iso_code = payload
  },
  RESET_USER_DATA (state) {
    state.defaultClientAddresse.city = null
    state.defaultClientAddresse.zipcode = null
    state.defaultClientAddresse.country = null

    state.defaultClientData.logged = false
    state.defaultClientData.id = null
    state.defaultClientData.email = null
    state.defaultClientData.phone = null
    state.defaultClientData.gender = null
    state.defaultClientData.birthday = null
    state.defaultClientData.first_name = null
    state.defaultClientData.last_name = null
  }
}
