import { api } from '../instance'

export const getAvailablePayments = (options = {}) => {
  const { countryIsoCode, amount, headers, instance } = options

  return (instance || api).get(
    `/secure/checkout/payments/available/${countryIsoCode}/${amount}`,
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const getPaymentsVouchersAndCredits = (options = {}) => {
  const { amount, headers, instance } = options

  return (instance || api).get(
    `/secure/checkout/payments/vouchers/${amount}`,
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const isOrderCreated = (options = {}) => {
  const { tpeReferenceId, headers, instance } = options

  return (instance || api).get(
    `/secure/checkout/is-order-created/${tpeReferenceId}`,
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const startActiveCheckoutSession = (options = {}) => {
  const { paymentEntityObject, headers, instance } = options

  return (instance || api).post(
    '/secure/active-checkout/session',
    { forPayment: paymentEntityObject },
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const commitActiveCheckoutSession = (options = {}) => {
  const { token, checkoutOrder, paymentRequirement, headers, instance } = options

  return (instance || api).post(
    `/secure/active-checkout/v2/${token}`,
    { checkoutOrder, paymentRequirement },
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}

export const commitPassiveCheckout = (options = {}) => {
  const { checkoutOrder, headers, instance } = options

  return (instance || api).post(
    '/secure/passive-checkout/v2',
    { ...checkoutOrder },
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}
