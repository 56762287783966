<template>
  <modal
    :name="name"
    :height="height"
    :width="width"
    :max-width="maxWidth"
    adaptive
    scrollable
    :class="`v-modal--${name}`"
    @before-open="open"
    @before-close="close"
    @opened="$emit('opened')"
    @closed="$emit('closed')"
  >
    <div ref="modal" class="c-modal" :class="'c-modal--' + name">
      <button class="c-modal__close" @click="$modal.hide(name)">
        <CloseIcon />
        <ArrowLeftIcon
          v-if="name === 'relayPoints'"
        />
      </button>
      <slot />
    </div>
  </modal>
</template>

<script>
import CloseIcon from '@/components/atoms/icons/Close'
import ArrowLeftIcon from '@/components/atoms/icons/ArrowLeft'
import 'vue-js-modal/dist/styles.css'
export default {
  components: { CloseIcon, ArrowLeftIcon },
  props: {
    name: {
      type: String,
      required: true
    },
    width: {
      type: [String, Number],
      default: 650
    },
    height: {
      type: String,
      default: 'auto'
    },
    maxWidth: {
      type: Number,
      default: Infinity
    }
  },
  destroyed () {
    const page = document.querySelector('html')
    page.classList.remove('has-overflow')
  },
  methods: {
    open () {
      this.$emit('open')
      const page = document.querySelector('html')
      page.classList.add('has-overflow')
    },
    close () {
      this.$emit('close')
      const page = document.querySelector('html')
      page.classList.remove('has-overflow')
    }
  }
}
</script>

<style lang="scss" scoped>
.c-modal {
  $c: &;

  position: relative;
  width: 100%;
  min-height: 300px;
  margin: 5rem auto;
  background-color: $white;

  @include mq($until: tablet) {
    max-width: 94%;
    min-height: auto;
  }

  &__close {
    position: absolute;
    z-index: 1;
    top: 2.3rem;
    right: 2.3rem;
    width: 2.2rem;
    height: 2.2rem;
    padding: 0;
    border: none;
    margin: 0;
    background-color: transparent;
    color: $grey-300;

    > svg {
      display: block;
      width: 100%;
      height: auto;
      fill: currentcolor;
    }

    @include mq($until: tablet) {
      top: 1.5rem;
      right: 1.5rem;
      width: 1.7rem;
      height: 1.7rem;
    }
  }

  &--forgotten {
    padding: 8rem 12.2rem 7.9rem 10.3rem;

    @include mq($until: tablet) {
      padding: 5.5rem 2.8rem;
    }
  }

  &--relayPoints {
    // stylelint-disable-next-line selector-class-pattern
    .c-modal__close {
      @include mq($until: tablet) {
        top: 1.7rem;
        right: auto;
        left: 2.1rem;
      }

      >svg {
        &:first-child {
          @include mq($until: tablet) {
            display: none;
          }
        }

        &:last-child {
          display: none;
          width: 1rem;
          height: auto;

          @include mq($until: tablet) {
            display: block;
          }
        }
      }
    }

    @include mq($until: tablet) {
      max-width: 100%;
      padding: 0;
      margin: 0;
    }
  }

  &--relayPointSchedule {
    max-width: 100%;
    min-height: auto;
    padding: 2rem 1.9rem 3rem;
  }

  &--ratings {
    overflow: hidden;
    max-width: 100vw;
    height: 100vh;
    padding: 4rem;
    margin: 0;
    overflow-y: auto;

    @include mq($until: tablet) {
      padding: 4rem 0;
    }
  }

  &--sizer {
    padding: 9.9rem 8.3rem 5.1rem 6.7rem;

    &::after {
      display: block;
      clear: both;
      content: "";
    }

    #{$c}__close {
      right: 11.3rem;

      @include mq($until: tablet) {
        right: 1.5rem;
      }
    }

    @include mq($until: tablet) {
      padding: 4.6rem 0.7rem 3.7rem;
    }
  }

  &--address {
    @include mq($until: tablet) {
      max-width: 100%;
      margin: 0;

      // stylelint-disable-next-line selector-class-pattern
      > .c-modal__close {
        z-index: 2;
        top: 2rem;
        right: 1.3rem;
      }
    }
  }

  &--coordonnees {
    max-width: 58rem;
    padding: 2.2rem;

    // stylelint-disable-next-line selector-class-pattern
    > .c-modal__close {
      top: 2.4rem;
      right: 2.4rem;

      @include mq($until: tablet) {
        top: 2rem;
        right: 1.3rem;
      }
    }

    @include mq($until: tablet) {
      max-width: 100%;
      height: 100%;
      padding: 0 1.1rem;
    }
  }

  &--phone {
    // stylelint-disable-next-line selector-class-pattern
    >.c-modal__close {
      top: 3rem;
      right: 3rem;
    }
  }

  &--h {
    @include mq($until: tablet) {
      padding: 2rem 1.9rem 3rem;

      // stylelint-disable-next-line selector-class-pattern
      >.c-modal__close {
        top: 2rem;
        right: 3rem;
      }
    }
  }

  &--refund {
    padding: 8.1rem 5rem 8.9rem;

    // stylelint-disable-next-line selector-class-pattern
    >.c-modal__close {
      top: 3rem;
      right: 3rem;
    }

    @include mq($until: tablet) {
      padding: 4.8rem 2.6rem 5.2rem;

      // stylelint-disable-next-line selector-class-pattern
      >.c-modal__close {
        top: 1.5rem;
        right: 1.5rem;
      }
    }
  }

  &--outlet {
    margin: 0 auto;
  }

  &--connect {
    min-height: 1rem;
    margin: 0 auto;

    @include mq($until: tablet) {
      max-width: 100%;
    }

    // stylelint-disable-next-line selector-class-pattern
    >.c-modal__close {
      top: 1.5rem;
      right: 1.5rem;
      width: 0.9rem;
      height: 0.9rem;

      @include mq($until: tablet) {
        top: 1.5rem;
        right: 1.5rem;
        width: 1rem;
        height: 1rem;
      }

      > svg {
        width: 100%;
        height: auto;
      }
    }
  }

  &::v-deep #page-cms {
    h1 {
      margin: 0;
      margin-bottom: 3.9rem;
      color: $grey-300;
      font-size: 1.8rem;
      font-weight: 900;
    }
  }
}
</style>
