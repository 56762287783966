import { isStaticPage } from '@/helpers/pageHelper'
import generateSeo from '@/helpers/generateSeo'
import { getReferer } from '@/helpers/referer'
import { sendSentryMessage } from '@/helpers/errorHelper'

/*
* Le middleware page est appelé sur toutes les pages du site
* Il est appelé depuis le nuxt.config
*/
export default async function (context) {
  const { $cookies, error, redirect, route, store } = context

  const staticPage = isStaticPage(route)
  if (store.state.homeBanner && route.path !== '/') {
    store.dispatch('setHomeBanner', null)
  }

  const RESPONSE_CODE = {
    NOT_FOUND: 404,
    PERMANENT_REDIRECTION: 301,
    REDIRECT: 302,
    OK: 200
  }

  const generateRedirection = (res) => {
    const queryParamArray = []
    if (res.url.queryParams.length || Object.keys(res.url.queryParams).length) {
      for (const [key, value] of Object.entries(res.url.queryParams)) {
        queryParamArray.push(`${key}=${value}`)
      }
    }
    let redirectUrl = queryParamArray.length ? `${res.url.path}?${queryParamArray.join('&')}` : res.url.path
    redirectUrl = redirectUrl.replace(/~/g, '%7E').replace(/#/g, '%23')
    return redirectUrl
  }

  const fetchGlobalPageInfo = async (pageInfo, path) => {
    try {
      const { infos } = await context.$axios.$post('/page-info', {
        pageInfo,
        path
      })
      store.dispatch('setGlobalPageInfo', infos)
      context.globalPageInfo = infos
    } catch (e) {
      sendSentryMessage.bind(context, 'Fail fetch global page Info', 'fatal', {
        action: 'set global page info',
        call: 'getInfo',
        exception: e
      })()
      return error({ statusCode: 404, message: 'Fail fetch global page Info' })
    }
  }

  if (!staticPage.static) {
    // haven't found better solution for server side url generation
    const protocole = context.env.URL_PROTOCOL || 'https'
    const host = process.server ? context.req.headers.host : window.location.host
    // Fix : rajout du / final retiré par localPath pour les url v2. A retirer quand on aura les URL full v3
    const url = `${protocole}://${host}${route.fullPath}`
    // pageUrl va etre utils pour fournir l'url du site
    // dans la balise open graph sur les fiches produits
    context.pageUrl = url
    try {
      const { parsed } = await context.$axios.$post('/parse', {
        path: route.fullPath,
        url,
        baseUrl: store.state.international.domain
      })
      // On verifie que le res.code est different de 200
      // Si 302 le parseUrl détecte un probleme dans l'url et retourne la bonne url
      // Il faut donc faire un redirect.
      switch (parsed.code) {
        case RESPONSE_CODE.NOT_FOUND:
          return error({ statusCode: 404, message: 'Page Not Found' })
        case RESPONSE_CODE.PERMANENT_REDIRECTION:
          redirect(301, generateRedirection(parsed))
          return
        case RESPONSE_CODE.REDIRECT:
          redirect(generateRedirection(parsed))
          return
        case RESPONSE_CODE.OK:
          await fetchGlobalPageInfo(parsed.pageInfo, parsed.url.path)
          break
      }
      context.page = parsed
    } catch (e) {
      sendSentryMessage.bind(context, 'Fail parseUrl', 'fatal', {
        action: 'set page context',
        call: 'parseUrl',
        exception: e
      })()
      return error({ statusCode: 404, message: 'Page Not Found' })
    }
  } else {
    store.dispatch('setGlobalPageInfo', null)
    context.globalPageInfo = null
  }

  try {
    const [affiliate] = await Promise.all([
      getReferer(context)
    ])

    if (affiliate) {
      $cookies.set('referer', affiliate.id, { path: '/', maxAge: 60 * 60 * 24 * 30 })
    }
  } catch (e) {
    sendSentryMessage.bind(context, 'Fail fetch affiliate', 'error', {
      action: 'set affiliate',
      call: 'getReferer',
      exception: e
    })()
  }
  generateSeo(context)
}
