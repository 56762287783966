import dayjs from 'dayjs'
import cloneDeep from 'lodash.clonedeep'
import { createBasketDetail } from '@/services/checkout'
import { sendSentryMessage } from '@/helpers/errorHelper'

export const state = () => ({
  // FIXME:  Data inconsistency --> since API is waiting an object on payment we should convert store basket to Object
  // WARNING: IMPACT WILL BE HUGE THROUGH CODE FUNCTIONNALITIES
  basket: [],
  basketItems: [],
  basketPromotion: null,
  displayBasketPromotionError: false,
  basketPromotionErrorMessage: null,
  price: null,
  defaultCarrier: null,
  priceWithDefaultCarrier: null,
  weight: null,
  device: null,
  displayUpdateTaxe: false,
  freeShippingAmount: 0,
  countryIsoCode: null,
  loading: false,
  error: false,
  shippingAddress: null,
  relayPoint: null,
  carrier: null,
  order: null,
  vouchers: [],
  credits: [],
  addressToFetchRelayPoint: null,
  purchaseData: null
})

export const getters = {
  basket (state) {
    return state.basket
  },
  nbProducts (state) {
    return state.basket.reduce((a, b) => a + b.quantity, 0)
  },

  productsId (state) {
    // return array of unique id of products
    return [...new Set(state.basket.map(item => item.info.id))]
  },

  simplifiedBasket (state) {
    return state.basket.map((item) => {
      const ret = { productId: item.info.id, quantity: item.quantity }
      if (item.size) {
        ret.productSizeId = item.size.id
      } else {
        ret.productSizeId = null
      }
      return ret
    })
  },

  priceWithDefaultCarrier (state) {
    return Math.round(state.priceWithDefaultCarrier * 100) / 100
  },

  price (state) {
    if (!state.basketItems?.length) {
      return 0
    }
    if (state.carrier) {
      if (!state.carrier.price) {
        return Math.round((state.price) * 100) / 100
      } else {
        const finalPrice = state.price + state.carrier?.price?.amount / 100
        return Math.round(Math.round(finalPrice * 100)) / 100
      }
    }
  },

  productPrice (state) {
    return Math.round((state.basket.reduce((a, b) => b.quantity > 0 ? a + b.quantity * b.info.price : a, 0)) * 100) / 100
  },
  defaultCarrierFreeShipping (state) {
    return state.defaultCarrier?.price.freeShipping
  },
  defaultCarrierFreeShippingPrice (state) {
    return state.defaultCarrier?.price.freeShippingAmount || 99
  },

  defaultCarrierShippingPrice (state) {
    if (!state.basketItems?.length) {
      return 0
    }
    if (state.defaultCarrier?.price.freeShipping && state.defaultCarrier?.price.freeShippingAmount < state.price) {
      return null
    } else {
      return state.defaultCarrier?.price.priceWithDuty
    }
  },

  defaultCarrierShippingDelay (state) {
    return state.defaultCarrier ? Math.ceil(dayjs(state.defaultCarrier.deliveryDate.deliveryDate).diff(dayjs(), 'h') / 24) : null
  },

  defaultCountryIsoCode (_, __, rootState) {
    const storeCountryCode = rootState.international.currentInterStore.countryCode

    return rootState.countries.find(country => country.isoCode === storeCountryCode).isoCode
  },

  freeShippingPrice (state) {
    if (state.carrier) {
      return !state.carrier?.price
    }
  },

  shippingPrice (state) {
    if (!state.basketItems?.length) {
      return 0
    }
    if (state.carrier) {
      if (!state.carrier.price) {
        return 0
      } else {
        return state.carrier?.price.amount
      }
    }
  },

  shippingPriceLegible (_, getters) {
    return getters.shippingPrice ? getters.shippingPrice / 100 : null
  },

  shippingDelay (state) {
    if (state.carrier) {
      return Math.ceil(dayjs(state.carrier.shippingDeliveryDate.deliveryDate).diff(dayjs(), 'h') / 24)
    }
  },

  totalDiscount (state) {
    const productDiscount = state.basket.reduce((a, b) => a + ((b.info.originPrice - b.info.price) * b.quantity), 0)
    const promotionDiscount = state.basketPromotion && state.basketPromotion.valid ? state.basketPromotion.discount : 0

    return productDiscount + promotionDiscount
  },

  checkoutBasket (state) {
    // extract only the required attribute from the basket
    return {
      basketItems: state.basketItems,
      basketPromotion: state.basketPromotion,
      defaultCarrier: state.defaultCarrier,
      price: state.price,
      priceWithDefaultCarrier: state.priceWithDefaultCarrier,
      weight: state.weight,
      countryIsoCode: state.countryIsoCode,
      device: state.device
    }
  },

  totalVouchersAndCredit (state) {
    return state.vouchers ? state.vouchers.reduce((a, b) => a + b.amount, 0) + state.credits.reduce((a, b) => a + b.amount, 0) : null
  }
}

export const actions = {
  setPurchaseData ({ commit }, payload) {
    commit('SET_PURCHASE_DATA', payload)
  },
  resetPromotionError ({ commit }, payload) {
    commit('RESET_PROMOTION_ERROR', payload)
  },
  setAddressToFetchRelayPoint ({ commit }, payload) {
    commit('SET_ADDRESS_TO_FETCH_RELAY_POINT', payload)
  },

  setDisplayUpdateTaxe ({ commit }, payload) {
    commit('SET_DISPLAY_UPDATE_TAXE', payload)
  },

  addProduct ({ commit, dispatch }, { info, size }) {
    commit('ADD_PRODUCT', { info, size })
    dispatch('updateBasket')
  },

  setProductQuantity ({ commit, dispatch }, { id, size, quantity }) {
    commit('SET_PRODUCT_QUANTITY', { id, size, quantity })
    dispatch('updateBasket')
  },

  removeProduct ({ commit, dispatch }, { id, size }) {
    commit('REMOVE_PRODUCT', { id, size })
    dispatch('updateBasket')
  },

  setCountryIsoCode ({ commit, dispatch }, payload) {
    commit('SET_COUNTRY_ISO_CODE', payload)
    dispatch('updateBasket')
  },

  setPromoCode ({ commit, dispatch }, payload) {
    commit('SET_PROMO_CODE', payload)
    return dispatch('updateBasket')
  },

  removePromo ({ commit, dispatch }) {
    commit('REMOVE_PROMO_CODE')
    dispatch('updateBasket')
  },
  synchronizerStorage ({ commit }) {
    commit('SYNCHRONIZER_STORAGE')
  },
  async getFreeShippingAmount ({ commit }) {
    try {
      const response = await this.$getCarrierFreeShipping()
      commit('SET_FREE_SHIPPING_AMOUNT', response.freeShipping)
    } catch (err) {
      sendSentryMessage.bind(this.app, 'Unable to fetch free shipping amount', 'fatal', {
        action: 'getFreeShippingAmount',
        file: 'store/basket.js',
        exception: err
      })()
    }
  },
  async updateBasket ({ commit, getters, state, dispatch }) {
    const countryIsoCode = state.countryIsoCode || getters.defaultCountryIsoCode
    const basketItems = getters.simplifiedBasket.filter(item => item.quantity > 0)

    if (!basketItems || !basketItems.length) {
      // TODO: refactor the update_basket commit - the state should not depend on the api response
      const fakeBasketResponse = {
        basketItems: [],
        defaultCarrier: state.defaultCarrier || null,
        price: 0,
        dutyFreePrice: 0,
        priceWithDefaultCarrier: 0,
        weight: 0,
        countryIsoCode,
        device: state.device || null
      }

      commit('UPDATE_BASKET', fakeBasketResponse)
      return
    }

    try {
      const response = await createBasketDetail({
        instance: this.$api,
        countryIsoCode,
        basketItems,
        basketPromotion: state.basketPromotion,
        device: (this.app.$ua.deviceType() === 'pc' || this.app.$ua.isFromTablet()) ? 0 : 1
      })
      commit('UPDATE_BASKET', response)
      return response
    } catch (error) {
      if (error.response?.status === 401) {
        const strategyName = this.$auth.strategy.name
        await this.$auth.strategies[strategyName].refreshToken(false)
        dispatch('updateBasket')
      } else {
        sendSentryMessage.bind(this.app, 'Unable to create basket detail', 'fatal', {
          action: 'updateBasket',
          country: countryIsoCode,
          call: 'createBasketDetail',
          exception: error.response ?? error
        })()
        commit('SET_ERROR')
      }
    }
  },

  setShippingAddress ({ commit }, payload) {
    commit('SET_SHIPPING_ADDRESS', payload)
  },
  setRelayPoint ({ commit }, payload) {
    commit('SET_RELAY_POINT', payload)
  },
  setCarrier ({ commit }, payload) {
    commit('SET_CARRIER', payload)
  },

  setOrder ({ commit }, payload) {
    commit('SET_ORDER', payload)
  },

  cleanBasket ({ commit }) {
    commit('CLEAN_BASKET')
  },
  cleanBasketLogout ({ commit, dispatch }) {
    commit('CLEAN_BASKET_LOGOUT')
    dispatch('updateBasket')
  },
  setVouchers ({ commit }, payload) {
    commit('SET_VOUCHERS', payload)
  },

  setCredits ({ commit }, payload) {
    commit('SET_CREDITS', payload)
  }

}

export const mutations = {
  SET_PURCHASE_DATA (state, payload) {
    state.purchaseData = payload
  },
  SYNCHRONIZER_STORAGE (state) {
    if (!window?.localStorage) {
      sendSentryMessage.bind(this, 'Cannot access localstorage, basket data cannot be synchronized', 'error', {
        window,
        ls: window?.localStorage
      })()
    }

    const storageVueX = JSON.parse(window.localStorage.getItem('vuex'))
    if (storageVueX) {
      state.basket = storageVueX.basket.basket.filter(product => product.quantity > 0)
      state.basketItems = storageVueX.basket.basketItems.filter(product => product.quantity > 0)
      state.price = storageVueX.basket.price
    }
  },
  SET_FREE_SHIPPING_AMOUNT (state, payload) {
    state.freeShippingAmount = payload
  },
  RESET_PROMOTION_ERROR (state) {
    state.displayBasketPromotionError = false
    state.basketPromotionErrorMessage = null
  },

  SET_ADDRESS_TO_FETCH_RELAY_POINT (state, payload) {
    state.addressToFetchRelayPoint = payload
  },

  SET_DISPLAY_UPDATE_TAXE (state, payload) {
    state.displayUpdateTaxe = payload
  },

  ADD_PRODUCT (state, { info, size }) {
    const basket = cloneDeep(state.basket)
    const index = basket.findIndex(item => item.info.id === info.id && (!item.size || item.size.id === size.id))
    if (state.basketPromotion && !state.basketPromotion.valid) {
      state.basketPromotion = null
    }
    state.loading = true
    if (index > -1) {
      // check if the product / size is already in the basket
      basket[index].quantity = basket[index].quantity + 1
      state.basket = basket
    } else {
      // overize, add the product
      state.basket = [...state.basket, { info, size, quantity: 1 }]
    }
  },

  SET_PRODUCT_QUANTITY (state, { id, size, quantity }) {
    const basket = [...state.basket]
    const index = basket.findIndex(item => item.info.id === id && (!item.size || item.size.id === size.id))
    state.loading = true
    if (state.basketPromotion && !state.basketPromotion.valid) {
      state.basketPromotion = null
    }
    if (index > -1) {
      basket[index].quantity = +quantity // + => Convert to integer
      state.basket = basket
    }
  },

  REMOVE_PRODUCT (state, { id, size }) {
    const basket = [...state.basket]
    const index = basket.findIndex(item => item.info.id === id && (!item.size || item.size.id === size.id))

    if (state.basketPromotion && !state.basketPromotion.valid) {
      state.basketPromotion = null
    }
    if (index > -1) {
      state.loading = true
      basket.splice(index, 1)
      state.basket = basket
    }
  },

  SET_PROMO_CODE (state, code) {
    state.loading = true
    state.basketPromotion = { code }
  },

  REMOVE_PROMO_CODE (state) {
    state.loading = true
    state.basketPromotion = null
  },

  UPDATE_BASKET (state, { basketItems, basketPromotion, price, weight, countryIsoCode, device, priceWithDefaultCarrier, defaultCarrier }) {
    // update price / promotion, etc...
    if (basketPromotion) {
      if (basketPromotion.valid) {
        state.basketPromotion = basketPromotion
        state.displayBasketPromotionError = false
        state.basketPromotionErrorMessage = null
      } else {
        state.basketPromotion = null
        state.displayBasketPromotionError = true
        state.basketPromotionErrorMessage = basketPromotion.promotionValidity.message
      }
    } else {
      state.basketPromotion = basketPromotion
    }
    state.price = basketItems.length ? Math.round((price) * 100) / 100 : 0
    state.weight = weight
    state.device = device
    state.countryIsoCode = countryIsoCode
    state.priceWithDefaultCarrier = priceWithDefaultCarrier
    state.defaultCarrier = defaultCarrier
    state.error = false
    if (basketItems) {
      const basket = [...state.basket]
      basketItems.forEach((item) => {
        const index = basket.findIndex((i) => {
          return i.info.id === item.productId && (!i.size || i.size.id === item.productSizeId)
        })
        if (index > -1) {
          if (basket[index].size) {
            basket[index].size.quantity = item.quantityAvailable
          }
          const quantity = item.quantityAvailable
          if (basket[index].quantity >= quantity) {
            basket[index].quantity = quantity
          }
          const originPrice = item.originPrice / basket[index].quantity
          const price = item.price / basket[index].quantity

          const info = { ...basket[index].info, originPrice, price, quantity }
          basket[index].info = info
        }
      })
      state.basket = basket.filter(product => product.quantity > 0)
      // store the baskets item for the checkout
      state.basketItems = basketItems.filter(product => product.quantity > 0)
    }
    state.loading = false
  },

  SET_COUNTRY_ISO_CODE (state, payload) {
    state.countryIsoCode = payload
  },

  SET_ERROR (state) {
    state.error = true
    state.loading = false
  },

  SET_SHIPPING_ADDRESS (state, payload) {
    state.shippingAddress = payload
    state.relayPoint = null
  },

  SET_RELAY_POINT (state, payload) {
    state.shippingAddress = null
    state.relayPoint = payload
  },

  SET_CARRIER (state, payload) {
    state.carrier = payload
  },

  SET_ORDER (state, payload) {
    state.order = payload
  },

  CLEAN_BASKET (state) {
    state.basket = []
    state.basketItems = []
    state.basketPromotion = null
    state.displayBasketPromotionError = false
    state.price = null
    state.defaultCarrier = null
    state.priceWithDefaultCarrier = null
    state.weight = null
    state.device = null
    state.shippingAddress = null
    state.relayPoint = null
    state.carrier = null
    state.vouchers = []
    state.credits = []
  },

  CLEAN_BASKET_LOGOUT (state) {
    state.defaultCarrier = null
    state.loading = false
    state.error = false
    state.countryIsoCode = null
    state.displayUpdateTaxe = false
    state.shippingAddress = null
    state.relayPoint = null
    state.carrier = null
    state.order = null
    state.vouchers = []
    state.credits = []
    state.addressToFetchRelayPoint = null
  },

  SET_VOUCHERS (state, payload) {
    state.vouchers = payload
  },

  SET_CREDITS (state, payload) {
    state.credits = payload
  }
}
