import { api } from './instance'
export const sendContactMessage = (options = {}) => {
  const { contactMessage, headers, instance } = options
  return (instance || api).post(
    '/contact/messages',
    { ...contactMessage },
    { headers }
  ).then(res => res.data)
    .catch((error) => {
      throw error
    })
}

export const getContactReasons = (options = {}) => {
  const { headers, instance } = options
  return (instance || api).get(
    '/contact/reasons',
    { headers }
  ).then(res => res.data)
    .catch((error) => {
      throw error
    })
}
