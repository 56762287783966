import algoliasearch from 'algoliasearch/lite'
import algoliasearchHelper from 'algoliasearch-helper'
import { generateAlgoliaIndex } from './generateAlgoliaIndex'

import {
  TYPE,
  LISTING_TYPE,
  ALGOLIA_CLIENT_ID,
  ALGOLIA_API_KEYS,
  ALGOLIAFACETS,
  ALGOLIA_ERROR_TYPES,
  generateDynamicFacetName
} from './../config/algolia/config'
import { ERROR_CODES } from './../config/errors/codes'

export function getAlgoliaIndexFromListingType ({
  algoliaIndex,
  listingType,
  backOfficeSettings
}) {
  const brandArtistIndex = backOfficeSettings === null ? false : backOfficeSettings.listingCustomRelevancySalesSort
  let index
  switch (listingType) {
    case TYPE.OUTLET:
    case TYPE.TOPSALES:
      index = generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.DEFAULT)
      break
    case TYPE.BESTOFFERS1:
      index = generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.BEST_OFFERS)
      break
    case TYPE.BESTOFFERS2:
      index = generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.DEFAULT)
      break
    case TYPE.ARTIST:
    case TYPE.BRAND:
      index = brandArtistIndex ? generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.ARTIST_BRAND) : generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.ARTIST_BRAND_SALES)
      break
    case TYPE.NOVELTY:
      index = generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.NOVELTY)
      break
    case TYPE.FLASH:
      index = generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.FLASH)
      break
    case TYPE.SEARCH:
      index = generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.SEARCH_RESULTS)
      break
    default:
      index = generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.DEFAULT)
      break
  }
  return index
}
export function lboAlgoliaSearchHelper ({
  type,
  filters = {},
  hitsPerPage = 48,
  queryParameters = {},
  backOfficeSettings = null,
  algoliaIndex,
  currentInterStoreId,
  currentInterStoreLangCode,
  pageUrl = null
}) {
  const client = algoliasearch(ALGOLIA_CLIENT_ID, ALGOLIA_API_KEYS[currentInterStoreId])
  const { sort } = queryParameters
  // Set index
  let index
  switch (sort) {
    case 'asc':
      index = generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.SORT_ASC)
      break
    case 'desc':
      index = generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.SORT_DESC)
      break
    case 'novelty':
      index = generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.SORT_NOVELTY)
      break
    case 'relevance':
    default:
      index = getAlgoliaIndexFromListingType({
        algoliaIndex,
        listingType: type,
        backOfficeSettings
      })
      break
  }
  const algoliaFilters = []

  if (type !== TYPE.OUTLET) {
    algoliaFilters.push('isOnlyOutlet=0')
  }
  switch (type) {
    case TYPE.BESTOFFERS1:
      algoliaFilters.push('displayInListing:home')
      break
    case TYPE.NOVELTY:
      algoliaFilters.push('displayInListing:nouveautes')
      break
    case TYPE.TOPSALES:
      algoliaFilters.push('displayInListing:top-ventes')
      break
    case TYPE.PROMO:
    case TYPE.SALES:
      algoliaFilters.push('displayInListing:promos')
      break
    case TYPE.FLASH:
      algoliaFilters.push('label:flash_sale')
      break
    case TYPE.OUTLET:
      algoliaFilters.push('displayInListing:outlet')
      break
    default:
      break
  }

  if (filters.categories && filters.categories.length > 0) {
    const category = filters.categories[0]
    algoliaFilters.push(`${ALGOLIAFACETS.global.category.id}=${category} OR ${ALGOLIAFACETS.global.category.parentId}=${category}`)
  }

  if (type === TYPE.BRAND) {
    const brand = filters.brands[0]
    algoliaFilters.push(`${ALGOLIAFACETS.global.brand.id}:${brand}`)
  }

  if (type === TYPE.ARTIST) {
    const artist = filters.artists[0]
    algoliaFilters.push(`${ALGOLIAFACETS.global.artists.id}:${artist}`)
  }
  const disjunctiveFacets = [
    ALGOLIAFACETS.global.artists.id,
    ALGOLIAFACETS.global.artists.name,
    generateDynamicFacetName(currentInterStoreLangCode, 'MATERIALS'),
    ALGOLIAFACETS.global.brand.name,
    generateDynamicFacetName(currentInterStoreLangCode, 'GENDERS'),
    generateDynamicFacetName(currentInterStoreLangCode, 'COLORS'),
    ALGOLIAFACETS.global.size.ranked.nameWithType,
    ALGOLIAFACETS.global.ranges.id,
    ALGOLIAFACETS.global.ranges.name,
    ALGOLIAFACETS.global.ranges.aggregate,
    generateDynamicFacetName(currentInterStoreLangCode, 'PRICE'),
    ALGOLIAFACETS.global.promotion,
    generateDynamicFacetName(currentInterStoreLangCode, 'ECO_FRIENDLY'),
    generateDynamicFacetName(currentInterStoreLangCode, 'EXCLU'),
    generateDynamicFacetName(currentInterStoreLangCode, 'CATEGORIES')
  ]

  const helper = algoliasearchHelper(client, index, {
    hitsPerPage,
    maxValuesPerFacet: 100,
    filters: algoliaFilters.join(' AND '),
    facets: ['isOnlyOutlet', 'displayInListing', 'label'],
    disjunctiveFacets
  })
  // ### add refinements based on urlParams

  // QueryParameters : used only when you are in a search not matching a specific brand/artist... then filter the results
  const refinements = (param) => {
    const marketLabel = {
      ecoResp: 'eco-label' in param,
      exclusivity: 'exclu-label' in param
    }
    const { query, page, prices, promotions, sizes, brands, materials, artists, colors, categories, ranges } = param
    // remove previous refinements
    helper.clearRefinements()
    if (marketLabel.ecoResp) {
      helper.addDisjunctiveFacetRefinement(
        generateDynamicFacetName(currentInterStoreLangCode, 'ECO_FRIENDLY'),
        param['eco-label'].replace('%20', ' ')
      )
    }
    if (marketLabel.exclusivity) {
      helper.addDisjunctiveFacetRefinement(
        generateDynamicFacetName(currentInterStoreLangCode, 'EXCLU'),
        param['exclu-label'].replace('%C3%A9', 'é')
      )
    }
    if (param.sort) {
      switch (param.sort) {
        case 'asc':
          helper.setIndex(generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.SORT_ASC))
          break
        case 'desc':
          helper.setIndex(generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.SORT_DESC))
          break
        case 'novelty':
          helper.setIndex(generateAlgoliaIndex(algoliaIndex, LISTING_TYPE.SORT_NOVELTY))
          break
        case 'relevance':
        default:
          helper.setIndex(getAlgoliaIndexFromListingType({
            algoliaIndex,
            listingType: type,
            backOfficeSettings
          }))
          break
      }
    }
    // query string
    if (query) {
      helper.setQuery(query)
    }
    // artists.name
    if (artists) {
      artists.split('~')
        .forEach(item =>
          helper.addDisjunctiveFacetRefinement(ALGOLIAFACETS.global.artists.name, item.replace('+', ' '))
        )
    }
    // brand.name
    if (brands) {
      brands.split('~')
        .forEach(item =>
          helper.addDisjunctiveFacetRefinement(ALGOLIAFACETS.global.brand.name, item.replace('+', ' '))
        )
    }
    // category.hierarchy.fr.lvl0
    if (categories) {
      categories
        .split('--')
        .forEach(item =>
          helper.addDisjunctiveFacetRefinement(
            generateDynamicFacetName(currentInterStoreLangCode, 'CATEGORIES'),
            item
          )
        )
    }
    // characteristics.fr.color
    if (colors) {
      colors
        .split('--')
        .forEach(item =>
          helper.addDisjunctiveFacetRefinement(
            generateDynamicFacetName(currentInterStoreLangCode, 'COLORS'),
            item.replace('%23', '#')
          )
        )
    }
    // gender.fr
    if (type === 'search') {
      const gender = generateDynamicFacetName(currentInterStoreLangCode, 'GENDERS')
      if (param[gender]) {
        const genders = param[gender].split('.')
        genders.forEach((item) => {
          helper.addDisjunctiveFacetRefinement(generateDynamicFacetName(currentInterStoreLangCode, 'GENDERS'), `${item.charAt(0).toUpperCase()}${item.slice(1)}`)
        })
      }
    }
    if (filters.sexes) {
      filters.sexes.forEach(item =>
        helper.addDisjunctiveFacetRefinement(generateDynamicFacetName(currentInterStoreLangCode, 'GENDERS'), item.charAt(0).toUpperCase() + item.slice(1)) // Capitalize gender to match with algolia filter
      )
    }
    // characteristics.fr.material
    if (materials) {
      materials
        .split('~')
        .forEach(item =>
          helper.addDisjunctiveFacetRefinement(
            generateDynamicFacetName(currentInterStoreLangCode, 'MATERIALS'),
            item.replace('+', ' ')
          )
        )
    }
    // ranges.id
    if ([TYPE.BRAND, TYPE.ARTIST].includes(type)) {
      if (filters.ranges && filters.ranges.length && pageUrl) {
        const rangesFromUrl = pageUrl.match(/([A-Z-a-z]+-[r][0-9]{3})/g)
        const rangesAggregate = rangesFromUrl.map((range) => {
          const rangeSplit = range.split('-')
          const rangeId = rangeSplit[rangeSplit.length - 1].split('r')[1]
          let rangeName = rangeSplit.slice(0, -1)
          rangeName = rangeName.map(item => item.charAt(0).toUpperCase() + item.slice(1)).join(' ')
          return `${rangeId}~~${rangeName}`
        })
        rangesAggregate.forEach((item) => {
          helper.addDisjunctiveFacetRefinement(ALGOLIAFACETS.global.ranges.aggregate, item)
        })
      }
    } else {
      // TODO: Not sure the ranges.id section is used in any way because of the specific traitment for brand and arstists pages
      // Keep it for now but may be good to delete
      // ranges.id
      if (filters.ranges && filters.ranges.length) {
        filters.ranges
          .forEach(item =>
            helper.addDisjunctiveFacetRefinement(ALGOLIAFACETS.global.ranges.id, item instanceof String ? item.replace('+', ' ') : item)
          )
      }

      // ranges.name
      if (ranges) {
        ranges.split('~')
          .forEach(item =>
            helper.addDisjunctiveFacetRefinement(ALGOLIAFACETS.global.ranges.name, item)
          )
      }
    }

    // prices.fr.price
    if (prices) {
      const [min, max] = prices.split('~')
      if (min) {
        helper.addNumericRefinement(generateDynamicFacetName(currentInterStoreLangCode, 'PRICE'), '>=', min)
      }
      if (max) {
        helper.addNumericRefinement(generateDynamicFacetName(currentInterStoreLangCode, 'PRICE'), '<=', max)
      }
    }

    // promotion.percent
    if (promotions) {
      const [min, max] = promotions.split('~')
      if (min) {
        helper.addNumericRefinement(ALGOLIAFACETS.global.promotion, '>=', min)
      }
      if (max) {
        helper.addNumericRefinement(ALGOLIAFACETS.global.promotion, '<=', max)
      }
    }

    // sizes.rankedNameWithType
    if (sizes) {
      sizes
        .split('--')
        .forEach(item =>
          helper.addDisjunctiveFacetRefinement(
            ALGOLIAFACETS.global.size.ranked.nameWithType,
            item
          )
        )
    }

    // page
    if (page) {
      helper.setPage(page - 1)
    }
  }

  refinements(queryParameters)

  return { helper, refinements }
}

export function algoliaErrorHandler (error) {
  const errorName = error.error?.name
  const isErrorFromAlgolia = errorName?.includes(Object.values(ALGOLIA_ERROR_TYPES))

  if (!isErrorFromAlgolia) {
    return error
  }

  switch (errorName) {
    case ALGOLIA_ERROR_TYPES.RETRY_ERROR:
      return handleAlgoliaRetryError(error)
    default:
      return error
  }
}

function handleAlgoliaRetryError (algoliaError) {
  const { error: { transporterStackTrace } } = algoliaError

  if (Array.isArray(transporterStackTrace)) {
    const isClientNetworkError = transporterStackTrace.every(trace => isResponseNetworkError(trace.response))

    if (isClientNetworkError) {
      return new Error('Network Error')
    }
  }
  return algoliaError
}

function isResponseNetworkError (response) {
  const { content, status } = response

  if (content) {
    return content.includes('Network request failed') && (status >= ERROR_CODES.SERVER || status === 0)
  }

  return false
}
