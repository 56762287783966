import DataStructure from './DataStructure'

export default class Money extends DataStructure {
  constructor ({
    amount,
    currency
  }) {
    super()
    this.amount = this.convertToNumber(amount)
    this.currency = this.convertToString(currency)
  }
}
