import { api } from '../instance'
export const validateGiftCard = (options = {}) => {
  const { giftCardCode, headers, instance } = options
  return (instance || api).post(
    `/secure/gift-cards/${giftCardCode}/validations`,
    {},
    { headers }
  ).then(res => res.data)
    .catch((e) => {
      throw e
    })
}
